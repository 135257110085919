import React from 'react';
import { useDispatch } from 'react-redux';
import { BulkMessage } from './BulkMessage';
import { bulkMessageActions } from 'resources/actions';

export function BulkMessagesList({ messages }) {
    const dispatch = useDispatch();

    function handleOnClose(messageId) {
        dispatch(bulkMessageActions.deleteMessage(messageId));
    }

    return (
        <div className="mb-4">
            <div className="flex justify-between items-center">
                <h3 className="text-lg font-bold text-gray-600">Messages</h3>
            </div>
            <div className="mt-4 max-h-[250px] overflow-y-auto">
                {messages.map((message) => (
                    <BulkMessage
                        key={message.id}
                        bulkMessage={message}
                        onClose={() => handleOnClose(message.id)}
                    />
                ))}
            </div>
        </div>
    );
}
