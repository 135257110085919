import { t } from 'i18next';

export function FacebookSupportFloatButton() {
    return (
        <div className="fixed bottom-0 right-5">
            <a
                href="http://m.me/replyem"
                target="_blank"
                rel="noreferrer"
                className="btn btn-primary text-sm flex"
            >
                <img
                    src="/assets/imgs/suport-icon.svg"
                    className="w-[14px] mr-2"
                />
                {t('support')}
            </a>
        </div>
    );
}
