import React from 'react';
import { useAuth } from '@resources/contexts';

export function UserAvatar({ ...props }) {
    const { fbUser } = useAuth();

    return (
        <img
            {...props}
            src={fbUser?.picture?.data?.url || '/assets/imgs/avatar.png'}
            alt="avatar"
        />
    );
}
