import { t } from 'i18next';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';

export function AffiliatesBanner() {
    const [isReferred, setIsReferred] = useState(window.Rewardful.referral);

    return (
        !isReferred && (
            <div className="mt-4 bg-[#ffd55c] py-2 text-center border-b-3 border-yellow-400">
                <i className="fa fa-money"></i> {t('earn_money_with_us')}!!!{' '}
                <i className="fa fa-money"></i>,{' '}
                <Link to="/affiliates" className="link underline">
                    {t('learn_more')}
                </Link>
            </div>
        )
    );
}
