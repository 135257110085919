import { messageService } from 'resources/services';
import { BULK_MESSAGE } from './types';
import { facebookService } from '@resources/services';
import { MESSAGE } from 'resources/constants';

export const bulkMessageActions = {
    getAllCustomers:
        (limit = null) =>
        async (dispatch, getState) => {
            dispatch({ type: BULK_MESSAGE.LOADING });

            const getNextCustomers = () => (dispatch, getState) => {
                const {
                    page: { page },
                    bulkMessage,
                } = getState();

                return facebookService
                    .getPageMessages(page.access_token, {
                        limit: 50,
                        after: bulkMessage.afterToken,
                    })
                    .then((response) => {
                        const { data, paging } = response;

                        const availableCustomers = data.filter(
                            (message) => message.can_reply
                        );

                        dispatch({
                            type: BULK_MESSAGE.SET,
                            payload: {
                                customers: [
                                    ...bulkMessage.customers,
                                    ...availableCustomers,
                                ],
                                customersTotal:
                                    bulkMessage.customersTotal +
                                    availableCustomers.length,
                                afterToken: paging?.cursors?.after,
                                isLastPage: paging?.next === undefined,
                            },
                        });
                    })
                    .catch((error) => {
                        console.error(error);
                        dispatch({
                            type: BULK_MESSAGE.SET,
                            payload: {
                                afterToken: null,
                                isLastPage: true,
                            },
                        });
                    });
            };

            do {
                await dispatch(getNextCustomers());
            } while (
                !getState().bulkMessage.isLastPage &&
                getState().bulkMessage.loading
            );

            dispatch({ type: BULK_MESSAGE.LOADED });
        },

    stackNewMessage: (messageContent) => async (dispatch) => {
        const message = await messageService
            .store({
                page_id: messageContent.page?.subscription?.id,
                message_type_id: MESSAGE.TYPES.BULK_MESSAGE,
                message: messageContent.message,
                label: JSON.stringify(messageContent.labels),
                tag: messageContent.facebookTag,
                total_messages: messageContent.customers.length,
            })
            .then((res) => res.data);

        dispatch({
            type: BULK_MESSAGE.ADD_MESSAGE,
            payload: {
                ...messageContent,
                id: message.id,
            },
        });
    },

    deleteMessage: (messageId) => async (dispatch, getState) => {
        const { bulkMessage } = getState();

        dispatch({
            type: BULK_MESSAGE.SET_MESSAGES,
            payload: bulkMessage.bulkMessages.filter(
                (message) => message.id !== messageId
            ),
        });
    },
};
