// Auth actions
export const AUTH = {
    LOGIN_SUCCESS: 'LOGIN_SUCCESS',
    LOGIN_FAIL: 'LOGIN_FAIL',
    LOGOUT: 'LOGOUT',

    SET_SUBSCRIPTION: 'SET_SUBSCRIPTION',
};

// Page actions
export const PAGE = {
    SET: 'SET_PAGE',
    RESET: 'RESET_PAGE',
    SET_ACCESS_TOKEN: 'SET_PAGE_ACCESS_TOKEN',

    LOADING: 'LOADING_PAGE',
    LOADED: 'LOADED_PAGE',
};

// Page customers actions
export const PAGE_CUSTOMERS = {
    SET: 'SET_PAGE_CUSTOMERS',
    RESET: 'RESET_PAGE_CUSTOMERS',

    FILTER: 'FILTER_PAGE_CUSTOMERS',

    LOADING: 'LOADING_PAGE_CUSTOMERS',
    LOADED: 'LOADED_PAGE_CUSTOMERS',
};

// Customer selected actions
export const CUSTOMER_SELECTED = {
    SET: 'SET_CUSTOMER_SELECTED',
    RESET: 'RESET_CUSTOMER_SELECTED',

    SET_MESSAGES: 'SET_CUSTOMER_SELECTED_MESSAGES',
    RESET_MESSAGES: 'RESET_CUSTOMER_SELECTED_MESSAGES',
    LOADING_MESSAGES: 'LOADING_CUSTOMER_SELECTED_MESSAGES',
    LOADED_MESSAGES: 'LOADED_CUSTOMER_SELECTED_MESSAGES',

    LOADING: 'LOADING_CUSTOMER_SELECTED',
    LOADED: 'LOADED_CUSTOMER_SELECTED',
};

// List of customers actions
export const LIST_OF_CUSTOMERS = {
    SET: 'SET_LIST_OF_CUSTOMERS',
    RESET: 'RESET_LIST_OF_CUSTOMERS',

    LOADING: 'LOADING_LIST_OF_CUSTOMERS',
    LOADED: 'LOADED_LIST_OF_CUSTOMERS',
};

// Bulk message actions
export const BULK_MESSAGE = {
    SET: 'SET_BULK_MESSAGE',
    RESET: 'RESET_BULK_MESSAGE',

    ADD_MESSAGE: 'ADD_BULK_MESSAGE_MESSAGE',
    SET_MESSAGES: 'SET_BULK_MESSAGE_MESSAGES',
    RESET_MESSAGES: 'RESET_BULK_MESSAGE_MESSAGES',

    LOADING: 'LOADING_BULK_MESSAGE',
    LOADED: 'LOADED_BULK_MESSAGE',
};
