import { PAGE, PAGE_CUSTOMERS } from './types';
import { pageService, facebookService } from '@resources/services';

export const pageActions = {
    getPage: (page) => (dispatch) => {
        dispatch({ type: PAGE.LOADING });

        return pageService
            .getPage(page)
            .then((res) => {
                dispatch({
                    type: PAGE.SET,
                    payload: {
                        page: {
                            ...page,
                            subscription: res?.data,
                        },
                    },
                });

                return res.data;
            })
            .finally(() => dispatch({ type: PAGE.LOADED }));
    },

    extendToken: (accessToken) => (dispatch) => {
        dispatch({ type: PAGE.LOADING });

        return pageService
            .extendToken(accessToken)
            .then((res) => {
                dispatch({
                    type: PAGE.SET_ACCESS_TOKEN,
                    payload: res.accessToken,
                });

                return res;
            })
            .finally(() => dispatch({ type: PAGE.LOADED }));
    },

    getPageCustomers: () => (dispatch, getState) => {
        const {
            page: { page },
            pageCustomers,
        } = getState();

        if (pageCustomers.isLastMessage) return Promise.resolve();

        dispatch({ type: PAGE_CUSTOMERS.LOADING });

        return facebookService
            .getPageMessages(page.access_token, {
                after: pageCustomers.afterToken,
            })
            .then((response) => {
                const { data, paging } = response;

                dispatch({
                    type: PAGE_CUSTOMERS.SET,
                    payload: {
                        customers: [...pageCustomers.customers, ...data],
                        afterToken: paging?.cursors?.after,
                        isLastMessage: paging?.next === undefined,
                    },
                });
            })
            .finally(() => dispatch({ type: PAGE_CUSTOMERS.LOADED }));
    },

    filterPageCustomers: (filter) => (dispatch) => {
        return dispatch({ type: PAGE_CUSTOMERS.FILTER, payload: filter });
    },
};
