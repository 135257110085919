import { combineReducers } from 'redux';
import { authReducer } from './auth.reducer';
import { pageReducer } from './page.reducer';
import { pageCustomersReducer } from './page-customers.reducer';
import { listOfCustomersSelectedReducer } from './list-of-customers-selected.reducer';
import { customerSelectedReducer } from './customer-selected.reducer';
import { bulkMessageReducer } from './bulk-message.reducer';

export default combineReducers({
    auth: authReducer,
    page: pageReducer,
    pageCustomers: pageCustomersReducer,
    listOfCustomersSelected: listOfCustomersSelectedReducer,
    customerSelected: customerSelectedReducer,
    bulkMessage: bulkMessageReducer,
});
