import { useRef, useState } from 'react';
import Fuse from 'fuse.js';

export function useSearch(records = [], options = {}, externalQuery = '') {
    const [query, setQuery] = useState('');
    const fuse = useRef(null);

    fuse.current = new Fuse(records, {
        threshold: 0.2, // 0.1 seems to be exact, default 0.6: brings too distant if anyhow related hits
        ignoreLocation: true, // default False: True - to search ignoring location of the words.
        findAllMatches: true,
        keys: [],
        ...options
    });

    const recordsFiltered = query.length || externalQuery.length ? fuse.current.search(externalQuery || query).map(item => item.item) : records;

    return {
        query: externalQuery || query,
        setQuery,
        records,
        recordsFiltered
    };
}
