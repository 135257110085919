//filipino fil
export default {
    translations: {
        search: 'Maghanap',
        social_inbox_marketing_is_born:
            'Ipinanganak ang Social Inbox Marketing',
        login_with_facebook: 'Mag-login gamit ang Facebook',
        logout: 'Mag-logout',
        login_description: `
            <strong>Ipadala</strong> ang mga mensahe sa
            <strong>inbox</strong> na itinuturo sa
            <strong>libu-libong mga tao</strong> na nakipag-ugnayan na sa iyong pahina.
            <br />
            <br />
            Piliin ang mga label na nais mo at magpadala ng promosyon, balita, o
            anumang nais mong ibahagi.
        `,
        search_page: 'Maghanap ng pahina...',
        people_like_this: 'Nagugustuhan ito ni {{ mga tao }}',
        view_page: 'Tingnan ang pahina',
        add_a_new_page: 'Magdagdag ng bagong pahina',
        support: 'Nangangailangan ng tulong?',
        earn_money_with_us: 'Kumita ng pera kasama kami',
        learn_more: 'Alamin pa',
        available_messages:
            'Mayroon kang {{ mga tao }} mga mensahe na magagamit.',
        need_more_messages: 'Kailangan mo pa ng higit pang mga mensahe?',
        messages: 'Mga Mensahe',
        search_and_filter_conversations: 'Maghanap at mag-filter ng mga usapan',
        loading: 'Naglo-load',
        loading_chat_messages: 'Naglo-load ng mga mensahe ng chat',
        loading_all_recipients: 'Naglo-load ng lahat ng mga tatanggap',
        send_message: 'Magpadala ng mensahe',
        send_bulk_message: 'Bagong Malalaking Mensahe',
        no_such_more_messages: ' Walang higit pang mga mensahe',
        write_your_message: 'I-type ang iyong mensahe',
        upload_image: 'Mag-upload ng larawan',
        incomplete_form: 'Hindi kumpletong porma',
        message_required: 'Mangyaring maglagay ng mensahe',
        you_have_no_messages: 'Wala kang mga mensahe na magagamit',
        please_purchase_a_subscription:
            'Mangyaring bumili ng isang subscription upang magpadala ng mga mensahe',
        buy_a_subscription: 'Bumili ng subscription',
        buy_a_addon: 'Bumili ng Addon',
        sending_message: 'Nagpapadala ng mensahe',
        sending_messages_description:
            'Nagpapadala ng mensahe kay {{ mga tao }} tatanggap.',
        step: 'Hakbang {{ hakbang }}',
        select_audience: 'Pumili ng audience',
        select_fb_tag: 'Pumili ng uri ng nilalaman ng iyong mensahe',
        select_audience_description1:
            'Pumili ng mga label para piliin kung kanino ipapadala ang mensahe',
        select_audience_description2:
            'Gumamit ng "lahat ng tatanggap" para piliin ang lahat sa iyong inbox sa pahina',
        select_audience_description3:
            'Maaari ka rin gumamit ng mga label para maalis ang mga tao',
        labels: 'Mga Label',
        recipients: 'Mga Tatanggap',
        all_recipients: 'Lahat ng tatanggap',
        no_label: 'Walang label',
        next_step: 'Susunod na hakbang',
        select_fb_tag_description:
            'Para sa mga tao sa iyong inbox na nag-interact ng higit sa 24 oras na ang nakalilipas',
        tag_confirmed_event_update: 'Kumpirmasyon ng Pag-update ng Evento',
        tag_confirmed_event_update_description: `
            Para sa mga taong nakumpirma ang kanilang pakikilahok sa isang event.
        `,
        tag_post_purchase_update: 'Pag-update Pagkatapos ng Pagbili',
        tag_post_purchase_update_description: `
            Para sa mga taong bumili na sa iyong aplikasyon.
        `,
        tag_account_update: 'Pag-update ng Account',
        tag_account_update_description: `
            Para sa mga taong may rehistradong account sa iyong aplikasyon.
        `,
        select_fb_tag_footer_description: `
            Pumili ng uri ng kontenteng ito para magpadala
            Ang mga mensahe na hindi kasama sa mga kategoryang ito ay maaaring
            magresulta sa pag-suspend ng iyong pahina sa Facebook™.
            <strong>
                MAGPADALA NG PROMOSYON GAMIT ANG MGA URI NG NILALAMAN NA ITO SA INYONG SARILING PAGPAPASIYA.
            </strong>
        `,
        more_info: 'Karagdagang impormasyon',
        write_your_message_and_send: 'I-type ang iyong mensahe at ipadala ito',
        this_message_will_be_sent_with_a_watermark:
            "Ang mensaheng ito ay ipadadala na may watermark ng reply'em, kung nais mong ipadala ang iyong mensahe nang walang aming watermark, maaari kang mag-subscribe.",
        this_messsage_will_be_sent_only_to:
            'Ang mensaheng ito ay ipadadala lamang sa {{ mga tao }} tatanggap.',
        messages_rejected:
            '{{ mga mensaheNaTinanggihan }} Mga Mensaheng Tinanggihan',
        messages_sent_successfully: `
            <strong>{{ mga mensaheNaNapadala }}</strong> mga mensahe na naipadala,
            <strong>{{ mgaMensaheNaMayKamalian }}</strong> mga tinanggihan.
        `,
        messages_in_progress: `Nagpapadala ng <strong>{{ mga mensaheNaNapadala }}</strong>/<strong>{{ kabuuangMgaMensahe }}</strong> mga mensahe.`,
        affiliates: {
            title: 'Mga Kasosyo',
            subtitle: 'Kumita ng pera kasama kami',
            how_it_works: 'Paano Ito Gumagana?',
            how_it_works_description: `
                Maaari kang kumita ng pera sa pamamagitan ng pag-imbita ng mga kaibigan, pamilya, o sino man,
                <strong class="text-primary">
                    Makakatanggap ka ng 20% ng lahat ng mga pagbili na ginawa ng iyong referido sa loob ng 2 buwan
                </strong>
                , ang iyong mga referido ay makakatanggap ng 15% na diskwento sa loob ng 3 buwan sa anumang plano.
            `,
            sign_up: 'Mag-sign up',
            sign_up_description: `
                Upang simulan ang pagkakitaan ng pera, kailangan mong magparehistro lamang sa aming programa ng mga kasosyo.
            `,
            invites: 'Mag-imbita',
            invites_description: `
                Imbitahan ang lahat ng iyong mga kaibigan, pamilya, o sinuman na gamitin
                ang replyem gamit ang link na aming gagawin sa aming programa ng referido.
            `,
            earn_money: 'Kumita ng pera',
            earn_money_description:
                'Simulan ang pagkakitaan ng pera kasama kami nang madali',
            start_earning_money_now: 'Simulan ang pagkakitaan ng pera ngayon',
            terms: 'Mga Tuntunin',
            terms_description: `
                Maaari kang humiling ng iyong unang pagbabayad kapag natamo mo na ang minimum na balanseng $10. Ang mga pagbabayad ay proseso sa pamamagitan ng
                PayPal. Bukod dito, mayroon kang pagkakataon na kumita ng 20% na komisyon
                sa lahat ng mga pagbili na ginawa ng iyong mga gumagamit sa unang 60 araw mula sa kanilang pagpaparehistro. Maaari mong
                subaybayan ang lahat ng iyong kita sa sumusunod na link
            `,
        },
        popular: 'Sikat',
        massive_messages: 'Mga Malalaking Mensahe',
        massive_replies: 'Mga Malalaking Tugon',
        pages: 'Mga Pahina',
        labeling: 'Pag-e-etiketa',
        choose_plan: 'Pumili ng Plano',
        choose_your_plan: 'Piliin ang Iyong Plano',
        rederal_discount: 'Discount para sa Referido',
        massive: 'Malalaki',
        month: 'Buwan',
        the_number_message: 'Ang bilang ng mensahe ay buwanang',
        plans: 'Mga Plano',
        plans_page: {
            title: 'Ang iyong plano ay nag-expire.',
            description: `Huwag mag-alala, maaari kang pumili ng isang plano at
                patuloy na mag-enjoy ng mga benepisyo na inaalok ng reply'em.`,
            mistake: 'Kung mayroon kang aktibong plano',
            link_mistake: 'makipag-ugnayan sa amin',
            referral_discount:
                'Ang discount para sa referral ay ipinapakita sa susunod na pahina.',
            starter_header: 'Starter',
            pequeno_negocio: 'Maliit na Negosyo',
            marketer_bussines: 'Marketer',
            ilimitado: 'Walang Limitasyon',
            starter_price_annually:
                'o {{ priceStarterAnnualy }} kapag binayaran taun-taon',
            pequeno_negocio_price_annually:
                'o {{ priceNegocioAnnualy }} kapag binayaran taun-taon',
            marketer_price_annually:
                'o {{priceMarketerAnnualy}} kapag binayaran taun-taon',
            unlimited_price_annually:
                'o {{priceUnlimitedAnnualy}} kapag binayaran taun-taon',
            months: '/buwan',
            unlimited: 'Walang Limitasyon',
            suscribe: 'Mag-subscribe',
        },
    },
};
