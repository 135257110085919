import React from 'react';
import { useFanPage } from 'resources/contexts';
import { BulkMessageWizard } from './BulkMessageWizard';
import { CustomerChat } from './CustomerChat';

/**
 * MessengerContent
 *
 * @returns {JSX.Element}
 */
export function MessengerContent() {
    const { showBulkMessageWizard } = useFanPage();

    return (
        <section className="page-inbox-content">
            {showBulkMessageWizard ? <BulkMessageWizard /> : <CustomerChat />}
        </section>
    );
}
