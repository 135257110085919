import React, { createContext, useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { sdk } from '@resources/helpers/facebookSDK';
import { useApp } from '@resources/contexts';
import { authActions } from '@resources/actions';

/**
 * Auth context.
 * Manages all the variable that are required
 */
export const AuthContext = createContext({});

/**
 * Hook to use the Auth context
 *
 * @returns The Auth context
 */
export function useAuth() {
    return useContext(AuthContext);
}

/**
 * Auth provider
 *
 * @param {any[]} props - The custom props of the component
 * @param {JSX.Element} props.children - The children
 * @returns {JSX.Element} A react component
 */
export function AuthProvider({ children }) {
    const { registerContextLoader, setContextLoaded } = useApp();
    const authStore = useSelector((state) => state.auth);
    const dispatch = useDispatch();

    useEffect(() => {
        registerContextLoader('AuthContext');

        sdk.init().then(() => {
            dispatch(authActions.checkAuthState()).finally(() => {
                setContextLoaded('AuthContext');
            });
        });
    }, []);

    useEffect(() => {
        if (authStore.subscription) {
            window.gtag('set', {
                user_id: authStore.subscription?.facebook_id,
            });
        }
    }, [authStore.subscription]);

    function logout() {
        dispatch(authActions.logout());
    }

    return (
        <AuthContext.Provider
            value={{
                ...authStore,
                sdk,

                logout,
            }}
        >
            {children}
        </AuthContext.Provider>
    );
}
