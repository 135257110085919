import { useEffect, useRef, useState } from 'react';
import { facebookService, fileService } from '@resources/services';
import { notifications } from '@mantine/notifications';
import { t } from 'i18next';

export function useSingleMessage({
    pageAccessToken,
    recipientId,
    facebookTag,
}) {
    const fileRef = useRef();
    const [message, setMessage] = useState('');
    const [imageUrl, setImageUrl] = useState('');
    const [sending, setSending] = useState(false);

    function restartForm() {
        setSending(false);
        setMessage('');
        setImageUrl('');
        fileRef.current = '';
    }

    function sendMessage() {
        setSending(true);

        return facebookService
            .sendMessage({
                recipientId,
                pageAccessToken,
                imageUrl,
                message,
                facebookTag,
            })
            .then(() => {
                setSending(false);
                restartForm();
            })
            .catch((error) => {
                console.error(error);

                if (error?.error?.message) {
                    notifications.show({
                        title: 'Message rejected',
                        message: error?.error?.message,
                        color: 'red',
                        autoClose: false,
                        loading: false,
                    });
                }
            });
    }

    function uploadImage(event) {
        if (!event.target.files.length) return;

        fileService.getUrlFile(event.target.files[0], 'single').then((res) => {
            setImageUrl(res.url);

            fileRef.current = '';
        });
    }

    function clearImage() {
        setImageUrl('');
    }

    useEffect(() => {
        restartForm();
    }, [recipientId]);

    return {
        // STATES
        message,
        sending,
        imageUrl,

        // METHODS
        setMessage,
        sendMessage,
        uploadImage,
        clearImage,
    };
}
