import { t } from 'i18next';
import React, { useEffect } from 'react';

/**
 * Affiliates
 * The affiliates page component
 *
 * @returns {JSX.Element} A react component
 */
export function Affiliates() {
    useEffect(() => {
        window.gtag('event', 'view_affiliates_page');
    }, []);

    const handleOpenLink = () => window.gtag('event', 'click_affiliates_link');

    return (
        <div className="block px-4 py-8 bg-white shadow-lg shadow-slate-200">
            <div id="header">
                <h1 className="text-[40px] text-black mb-0 font-bold text-center">
                    {t('affiliates.title')}
                </h1>
                <small className="block text-center text-[18px] text-gray-400 font-bold">
                    {t('affiliates.subtitle')}
                </small>
            </div>
            <div id="how-it-works" className="mt-4">
                <h2 className="text-[30px] text-black font-bold">
                    {t('affiliates.how_it_works')}
                </h2>
                <p className="text-gray-400">
                    <div
                        dangerouslySetInnerHTML={{
                            __html: t('affiliates.how_it_works_description'),
                        }}
                    />
                </p>
            </div>
            <div id="step1" className="mt-4">
                <h2 className="text-[30px] text-black font-bold">
                    {t('step', { step: 1 })}: {t('affiliates.sign_up')}
                </h2>
                <p className="text-gray-400">
                    {t('affiliates.sign_up_description')}
                    <a
                        href="https://affiliates.replyem.com/signup"
                        onClick={handleOpenLink}
                        className="text-primary"
                        target="_blank"
                        rel="noreferrer"
                    >
                        https://affiliates.replyem.com/signup
                    </a>
                </p>
            </div>
            <div id="step2" className="mt-4">
                <h2 className="text-[30px] text-black font-bold">
                    {t('step', { step: 2 })}: Invites
                </h2>
                <p className="text-gray-400">
                    {t('affiliates.invites_description')}
                </p>
            </div>

            <div id="step3" className="mt-4">
                <h2 className="text-[30px] text-black font-bold">
                    {t('step', { step: 3 })}: {t('affiliates.earn_money')}
                </h2>
                <p className="text-gray-400">
                    {t('affiliates.earn_money_description')}
                </p>
            </div>

            <div className="text-center">
                <a
                    href="https://affiliates.replyem.com/signup"
                    onClick={handleOpenLink}
                    className="btn btn-primary p-4"
                    target="_blank"
                    rel="noreferrer"
                >
                    <i className="fa fa-money"></i>{' '}
                    {t('affiliates.start_earning_money_now').toUpperCase()}!!!
                </a>
            </div>

            <div id="terms" className="mt-8 text-[12px] text-gray-400">
                <strong>{t('affiliates.terms')}:</strong>
                <br />
                <span
                    dangerouslySetInnerHTML={{
                        __html: t('affiliates.terms_description'),
                    }}
                />
                <a
                    href="https://affiliates.replyem.com/login"
                    onClick={handleOpenLink}
                    className="text-primary"
                    target="_blank"
                    rel="noreferrer"
                >
                    https://affiliates.replyem.com/login
                </a>
            </div>
        </div>
    );
}
