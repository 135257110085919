import { initReactI18next } from 'react-i18next';
import i18n from 'i18next';
import * as resources from './lang';

/**
 * Gets the language of the browser
 * @returns The language that is being used by the browser
 */
const getLanguage = () => {
    let language = 'en';

    if (window.navigator.languages) {
        language = window.navigator.languages[0];
    } else {
        language =
            window.navigator['userLanguage'] || window.navigator.language;
    }

    language = language.split('-')[0];

    return language;
};

i18n.use(initReactI18next).init({
    fallbackLng: 'en',
    lng: getLanguage(),
    defaultNS: 'translations',
    resources,
    interpolation: {
        escapeValue: false,
    },
});

export default i18n;
