import React from 'react';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { authActions } from '@resources/actions';
import { t } from 'i18next';

export function PageCard({ page }) {
    const pageConnected = !!page.access_token;
    const dispatch = useDispatch();

    function handleConnectPage() {
        dispatch(authActions.login());
    }

    return (
        <div className="max-w-sm bg-white border border-gray-200 shadow-md">
            {pageConnected ? (
                <Link to={page.id}>
                    <div
                        className="h-[200px] bg-cover bg-center"
                        style={{
                            backgroundImage: `url(${page.picture.data.url})`,
                        }}
                    />
                </Link>
            ) : (
                <div
                    className="h-[200px] bg-cover bg-center grayscale"
                    style={{
                        backgroundImage: `url(${page.picture.data.url})`,
                    }}
                />
            )}
            <div className="p-2">
                {pageConnected ? (
                    <Link
                        to={`/${page.id}`}
                        className="text-[12px] border float-right top-0 font-medium p-1 text-gray-700 hover:bg-primary hover:text-white focus:ring-4 focus:outline-none focus:ring-blue-300"
                    >
                        {t('view_page')}
                    </Link>
                ) : (
                    <span
                        className="text-[12px] cursor-pointer border float-right top-0 font-medium p-1 bg-primary text-white focus:ring-4 focus:outline-none focus:ring-blue-300 px-2"
                        onClick={handleConnectPage}
                    >
                        Connect page
                    </span>
                )}
                <Link to={`/${page.id}`}>
                    <h5 className="text-sm font-bold tracking-tight text-gray-700">
                        {page.name}
                    </h5>
                </Link>
                <p className="text-xs font-normal text-gray-400">
                    {t('people_like_this', { people: page.fan_count })}
                </p>
            </div>
        </div>
    );
}
