import React, { useEffect, useState } from 'react';
import { LoadingOverlay } from '@mantine/core';
import { useDispatch } from 'react-redux';
import dayjs from 'dayjs';
import { useFanPage } from '@resources/contexts';
import { useScroll } from '@resources/hooks';
import { recipientMessageActions as recipientMessageActions } from '@resources/actions';
import { LoadingChat } from './LoadingChat';
import { t } from 'i18next';
import { useSingleMessage } from 'resources/hooks';

import './CustomerChat.scss';

/**
 * CustomerChat
 *
 * @returns {JSX.Element}
 */
export function CustomerChat() {
    const {
        page: { access_token, picture },
        customerSelectedStore: {
            id,
            updated_time,
            customer,
            loadingMessages,
            messages,
            isLastMessage,
        },
    } = useFanPage();
    const {
        loading,
        message,
        imageUrl,
        fileRef,
        setMessage,
        sendMessage,
        uploadImage,
        clearImage,
    } = useSingleMessage({
        pageAccessToken: access_token,
        recipientId: customer.id,
        facebookTag: 'CONFIRMED_EVENT_UPDATE',
    });
    const { scrollRefElement, goToScroll } = useScroll({
        onScrollStart: getMessages,
    });
    const [userImage, setUserImage] = useState('../assets/imgs/picture.gif');
    const dispatch = useDispatch();

    useEffect(() => {
        setUserImage(
            `https://graph.facebook.com/${customer.id}/picture?type=normal&access_token=${access_token}`
        );

        dispatch(recipientMessageActions.setLoadingMessages());

        refreshRecipientMessages();
    }, [id]);

    async function refreshRecipientMessages() {
        await dispatch(recipientMessageActions.resetMessages());

        getMessages().then(() =>
            goToScroll(scrollRefElement.current?.scrollHeight)
        );
    }

    function getMessages() {
        if (id && !isLastMessage && !loadingMessages) {
            return dispatch(
                recipientMessageActions.getNextMessages(access_token, id)
            ).then(({ payload }) => {
                if (payload.beforeToken) {
                    goToScroll(
                        document.getElementById(payload.preLatestMessageId)
                            ?.offsetTop
                    );
                }
            });
        }

        return Promise.resolve();
    }

    function handleSendMessage() {
        if (message) {
            sendMessage().then(refreshRecipientMessages);
        }
    }

    if (loadingMessages) {
        return <LoadingChat />;
    }

    if (!id) {
        return <div>...</div>;
    }

    return (
        <div className="chat">
            <div className="flex sm:items-center justify-between py-3 border-b-2 border-gray-200">
                <div className="relative flex items-center space-x-4">
                    <div className="relative">
                        <img
                            onError={() =>
                                setUserImage('../assets/imgs/picture.gif')
                            }
                            src={userImage}
                            className="w-10 sm:w-16 h-10 sm:h-16 rounded-full"
                        />
                    </div>
                    <div className="flex flex-col leading-tight">
                        <div className="text-xl mt-1 flex items-center">
                            <a
                                href={`https://www.facebook.com/profile.php?id=${customer.id}`}
                                target="_blank"
                                rel="noreferrer"
                                className="text-gray-700 mr-3"
                            >
                                {customer.name}
                            </a>
                        </div>
                        <span className="text-sm text-gray-600">
                            {dayjs(updated_time).format('LL')}
                        </span>
                    </div>
                </div>
            </div>
            <div
                id="messages"
                ref={scrollRefElement}
                className="chat-messages scrollbar-thumb-blue scrollbar-thumb-rounded scrollbar-track-blue-lighter scrollbar-w-2 scrolling-touch"
            >
                {[...messages].reverse().map((message) => {
                    return (
                        <div
                            key={message.id}
                            id={message.id}
                            className="chat-message"
                        >
                            <div
                                className={
                                    message.from.id === customer.id
                                        ? 'left-message'
                                        : 'right-message'
                                }
                            >
                                <div className="flex flex-col space-y-2 text-xs max-w-xs mx-2 order-2 items-start">
                                    <div>
                                        <span className="chat-message-content">
                                            {message.message}
                                        </span>
                                    </div>
                                </div>
                                <img
                                    src={
                                        message.from.id === customer.id
                                            ? userImage
                                            : picture?.data?.url
                                    }
                                    alt="My profile"
                                    className="w-6 h-6 rounded-full order-1"
                                />
                            </div>
                        </div>
                    );
                })}
            </div>
            <div>
                <label htmlFor="chat" className="sr-only">
                    {t('write_your_message')}!
                </label>
                <div className="flex items-center relative px-3 py-2 rounded-lg bg-gray-50">
                    <LoadingOverlay
                        visible={loading}
                        zIndex={1000}
                        overlayProps={{
                            radius: 'md',
                            blur: 3,
                            backgroundOpacity: 0,
                        }}
                    />
                    <textarea
                        id="chat"
                        rows="1"
                        onChange={(e) => setMessage(e.target.value)}
                        value={message}
                        className="block mx-4 p-2.5 w-full min-h-[41px] max-h-[70px] text-sm text-gray-900 bg-white rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500"
                        placeholder={`${t('write_your_message')}!`}
                    ></textarea>

                    {imageUrl ? (
                        <div className="relative">
                            <img
                                className="rounded-full items-start flex-shrink-0"
                                width="32"
                                height="32"
                                src={imageUrl}
                            />
                            <div
                                className="absolute top-[-5px] right-[-5px] bg-gray-300 rounded-full text-gray-600 cursor-pointer px-1 py-0 text-[12px]"
                                onClick={() => clearImage()}
                            >
                                x
                            </div>
                        </div>
                    ) : (
                        <label
                            htmlFor="file-upload"
                            className="inline-flex justify-center p-2 text-gray-500 rounded-lg cursor-pointer hover:text-gray-900 hover:bg-gray-100"
                        >
                            <input
                                type="file"
                                className="hidden"
                                ref={fileRef}
                                onChange={uploadImage}
                                id="file-upload"
                                name="file-upload"
                                accept="image/*"
                            />

                            <svg
                                aria-hidden="true"
                                className="w-6 h-6"
                                fill="currentColor"
                                viewBox="0 0 20 20"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    fillRule="evenodd"
                                    d="M4 3a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V5a2 2 0 00-2-2H4zm12 12H4l4-8 3 6 2-4 3 6z"
                                    clipRule="evenodd"
                                ></path>
                            </svg>
                            <span className="sr-only">{t('upload_image')}</span>
                        </label>
                    )}

                    <button
                        type="button"
                        onClick={handleSendMessage}
                        className="inline-flex justify-center p-2 text-primary rounded-full cursor-pointer hover:bg-blue-100"
                    >
                        <svg
                            aria-hidden="true"
                            className="w-6 h-6 rotate-90"
                            fill="currentColor"
                            viewBox="0 0 20 20"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path d="M10.894 2.553a1 1 0 00-1.788 0l-7 14a1 1 0 001.169 1.409l5-1.429A1 1 0 009 15.571V11a1 1 0 112 0v4.571a1 1 0 00.725.962l5 1.428a1 1 0 001.17-1.408l-7-14z"></path>
                        </svg>
                        <span className="sr-only">{t('send_image')}</span>
                    </button>
                </div>
            </div>
        </div>
    );
}
