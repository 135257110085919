import React from 'react';
import { ButtonSuscription } from '@features/components/ButtonSuscription';
import { t } from 'i18next';

export function RequireMoreMessages() {
    return (
        <div className="bulk-loader">
            {t('you_have_no_messages')}, {t('please_purchase_a_subscription')}
            <div className="mt-3">
                <ButtonSuscription />
            </div>
        </div>
    );
}
