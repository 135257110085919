import React, { useEffect, useState } from 'react';
import { t } from 'i18next';
import { useNavigate } from 'react-router';

import { subscriptionService } from '@resources/services';
import { useAuth } from '@resources/contexts';
import { FacebookSupportFloatButton } from '@features/layouts/ReplyemLayout/FacebookSupportFloatButton';
import { UserAvatar } from '@features/components';

import './Plans.scss';

export function Plans() {
    const [coupon] = useState(window.Rewardful?.coupon || {});
    const {
        subscription,
        fbUser: { id, name },
    } = useAuth();
    const navigation = useNavigate();

    function handleSelectPlan(priceId) {
        subscriptionService
            .getStripeCheckoutLink({
                price_id: priceId,
            })
            .then((res) => {
                window.open(res.data.url, '_blank');
            });
    }

    useEffect(() => {
        if (process.env.NODE_ENV === 'production' && subscription.access) {
            navigation('/');
        }
    }, []);

    return (
        <div className="plan-screen" id="plans">
            <FacebookSupportFloatButton />

            <div className="container">
                <header className="md:flex w-full">
                    <div className="w-4/6">
                        <h2>{t('plans_page.title')}</h2>
                        <p>{t('plans_page.description')}</p>
                    </div>
                    <div className="w-2/6 md:text-right mt-4 md:mt-0">
                        <div className="w-full flex flex-end gap-2 items-center md:justify-end">
                            <div className="text-md items-center justify-center">
                                <p className="font-bold text-gray-700">
                                    {name}
                                </p>
                                <p className="text-gray-400 text-sm">
                                    Id: {id}
                                </p>
                            </div>
                            <div className="contents">
                                <UserAvatar className="w-20 h-20 rounded-full shadow-sm" />
                            </div>
                        </div>
                    </div>
                </header>
                <section className="plans">
                    {coupon?.id && (
                        <div className="my-4 bg-[#ffd55c] text-black py-2 text-center border-b-3 border-yellow-400">
                            {t('plans_page.referral_discount')}
                        </div>
                    )}
                    <ul>
                        <li className="planOne">
                            <div className="top-gradient"></div>
                            <header>
                                <h3>{t('plans_page.starter_header')}</h3>
                                <p>
                                    {/*El precio se dejara como constante global en src/constants*/}
                                    <strong>14.99</strong>
                                    {t('plans_page.months')}
                                    <br />
                                    <em>
                                        {t(
                                            'plans_page.starter_price_annually',
                                            {
                                                priceStarterAnnualy: 149.99,
                                            }
                                        )}
                                    </em>
                                </p>
                            </header>
                            <ul className="specs">
                                <li>
                                    <b>2 000</b> {t('massive_messages')}
                                    <span className="text-[red]">*</span>
                                </li>
                                <li>
                                    <b>2 000</b> {t('massive_replies')}
                                </li>
                                <li>
                                    <b>{t('plans_page.unlimited')}</b>{' '}
                                    {t('pages')}
                                </li>
                                <li>
                                    <b>{t('massive')}</b> {t('messages')}{' '}
                                    {t('union_y')} {t('labeling')}
                                </li>
                            </ul>
                            <span
                                className="btn-buy"
                                onClick={() =>
                                    handleSelectPlan(
                                        'price_1MKsBqDVJ9D2bYPZNXL6l1UR'
                                    )
                                }
                            >
                                {t('plans_page.suscribe')}
                            </span>
                        </li>
                        <li className="planTwo">
                            <div className="top-gradient"></div>
                            <header>
                                <h3>{t('plans_page.pequeno_negocio')}</h3>
                                <p>
                                    <strong>29.99</strong>
                                    {t('plans_page.months')}
                                    <br />
                                    <em>
                                        {t(
                                            'plans_page.pequeno_negocio_price_annually',
                                            {
                                                priceNegocioAnnualy: 299.99,
                                            }
                                        )}
                                    </em>
                                </p>
                            </header>
                            <ul className="specs">
                                <li>
                                    <b>10 000</b> {t('massive_messages')}
                                    <span className="text-[red]">*</span>
                                </li>
                                <li>
                                    <b>10 000</b> {t('massive_replies')}
                                </li>
                                <li>
                                    <b>{t('plans_page.unlimited')}</b>{' '}
                                    {t('pages')}
                                </li>
                                <li>
                                    <b>{t('massive')}</b> {t('messages')}{' '}
                                    {t('union_y')} {t('labeling')}
                                </li>
                            </ul>
                            <span
                                className="btn-buy"
                                onClick={() =>
                                    handleSelectPlan(
                                        'price_1MKsHCDVJ9D2bYPZmkfhHyw6'
                                    )
                                }
                            >
                                {t('plans_page.suscribe')}
                            </span>
                        </li>
                        <li className="planThree">
                            <div className="top-gradient"></div>
                            <header>
                                <h3>{t('plans_page.marketer_bussines')}</h3>
                                <p>
                                    <strong>49.99</strong>
                                    {t('plans_page.months')}
                                    <br />
                                    <em>
                                        {t(
                                            'plans_page.marketer_price_annually',
                                            {
                                                priceMarketerAnnually: 499.99,
                                            }
                                        )}
                                    </em>
                                </p>
                            </header>
                            <ul className="specs">
                                <li>
                                    <b>50 000</b> {t('massive_messages')}
                                    <span className="text-[red]">*</span>
                                </li>
                                <li>
                                    <b>50 000</b> {t('massive_replies')}
                                </li>
                                <li>
                                    <b>{t('plans_page.unlimited')}</b>{' '}
                                    {t('pages')}
                                </li>
                                <li>
                                    <b>{t('massive')}</b> {t('messages')}{' '}
                                    {t('union_y')} {t('labeling')}
                                </li>
                            </ul>
                            <span
                                className="btn-buy"
                                onClick={() =>
                                    handleSelectPlan(
                                        'price_1MKsNVDVJ9D2bYPZ1LEm8rdj'
                                    )
                                }
                            >
                                {t('plans_page.suscribe')}
                            </span>
                        </li>
                        <li className="planFour">
                            <div className="top-gradient"></div>
                            <header>
                                <h3>{t('plans_page.ilimitado')}</h3>
                                <p>
                                    <strong>99.99</strong>
                                    {t('plans_page.months')}
                                    <br />
                                    <em>
                                        {t(
                                            'plans_page.unlimited_price_annually',
                                            {
                                                priceUnlimitedAnnually: 999.99,
                                            }
                                        )}
                                    </em>
                                </p>
                            </header>
                            <ul className="specs">
                                <li>
                                    <b>{t('plans_page.unlimited')}</b>{' '}
                                    {t('massive_messages')}
                                    <span className="text-[red]">*</span>
                                </li>
                                <li>
                                    <b>{t('plans_page.unlimited')}</b>{' '}
                                    {t('massive_replies')}
                                </li>
                                <li>
                                    <b>{t('plans_page.unlimited')}</b>{' '}
                                    {t('pages')}
                                </li>
                                <li>
                                    <b>{t('massive')}</b> {t('messages')}{' '}
                                    {t('union_y')} {t('labeling')}
                                </li>
                            </ul>
                            <span
                                className="btn-buy"
                                onClick={() =>
                                    handleSelectPlan(
                                        'price_1MKsSUDVJ9D2bYPZItRwxdVf'
                                    )
                                }
                            >
                                {t('plans_page.suscribe')}
                            </span>
                        </li>
                    </ul>

                    <div></div>
                </section>
                <div className="text-[14px] text-gray-500">
                    <div className="pt-4">
                        <span className="text-[red]">*</span>{' '}
                        {t('the_number_message')}
                    </div>
                </div>
            </div>
        </div>
    );
}
