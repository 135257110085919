import classNames from 'classnames';
import { t } from 'i18next';
import React from 'react';

export function Plan({
    title,
    price,
    specs: { massiveMessages, massiveReplies, pages, labeling },
    highlight = false,
    onSelectPlan = () => {},
}) {
    return (
        <div
            className={classNames({
                plan: true,
                'plan-highlight': highlight,
            })}
            onClick={onSelectPlan}
        >
            {highlight && (
                <div className="absolute left-[-52px] top-[15px] w-[170px] text-[14px] transform -rotate-45 bg-primary text-center text-white font-semibold py-1">
                    {t('popular').toUpperCase()}
                </div>
            )}

            <div className="plan-header">
                <h2 className="plan-title">{title}</h2>
                <div className="flex justify-center items-baseline">
                    <sup className="mb-[20px] text-[18px] font-extralight mr-[2px]">
                        $
                    </sup>
                    <span className="mr-1 text-[40px] font-normal text-primary">
                        {price}
                    </span>
                    <span className="font-light text-gray-400">/t{('month')}</span>
                </div>
            </div>

            <ul className="plan-specs">
                <li>
                    <i className="fa fa-check text-green-500"></i>
                    <span>
                        <b>{massiveMessages}</b> {t('massive_messages')}{' '}
                        <span className="text-[red]">*</span>
                    </span>
                </li>
                <li>
                    <i className="fa fa-check text-green-500"></i>
                    <span>
                        <b>{massiveReplies}</b> {t('massive_replies')}{' '}
                        <span className="text-[red]">*</span>
                    </span>
                </li>
                <li>
                    <i className="fa fa-check text-green-500"></i>
                    <span>
                        <b>{pages}</b> {t('pages')}{' '}
                        <span className="text-[red]">*</span>
                    </span>
                </li>
                <li>
                    <i className="fa fa-check text-green-500"></i>
                    <span>
                        <b>{labeling}</b> {t('labeling')}{' '}
                        <span className="text-[red]">*</span>
                    </span>
                </li>
            </ul>

            <button className="btn btn-block mt-auto w-full rounded-2xl">
                {t('choose_plan')}
            </button>
        </div>
    );
}
