import { CUSTOMER_SELECTED } from '../actions/types';

const initialState = {
    loading: false,
    threadId: null,
    customer: {},

    messages: [],
    loadingMessages: true,
    afterToken: null,
    beforeToken: null,
    isLastMessage: false,
    preLatestMessageId: null,
};

export function customerSelectedReducer(state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case CUSTOMER_SELECTED.SET:
            return {
                ...state,
                ...payload,
            };
        case CUSTOMER_SELECTED.LOADING:
            return {
                ...state,
                loading: true,
            };
        case CUSTOMER_SELECTED.LOADED:
            return {
                ...state,
                loading: false,
            };
        case CUSTOMER_SELECTED.LOADING_MESSAGES:
            return {
                ...state,
                loadingMessages: true,
            };
        case CUSTOMER_SELECTED.LOADED_MESSAGES:
            return {
                ...state,
                loadingMessages: false,
            };
        case CUSTOMER_SELECTED.SET_MESSAGES:
            return {
                ...state,
                messages: payload.messages,
                afterToken: payload.afterToken,
            };
        case CUSTOMER_SELECTED.RESET_MESSAGES:
            return {
                ...state,
                messages: [],
                afterToken: null,
                beforeToken: null,
                loadingMessages: false,
                isLastMessage: false,
            };
        case CUSTOMER_SELECTED.RESET:
            return initialState;
        default:
            return state;
    }
}
