import React, { createContext, useContext } from 'react';

/**
 * FanPage context.
 * Manages all the variable that are required
 */
export const FanPageContext = createContext({});

/**
 * Hook to use the FanPage context
 *
 * @returns The FanPage context
 */
export function useFanPage() {
    return useContext(FanPageContext);
}

/**
 * FanPage provider
 *
 * @param {any[]} props - The custom props of the component
 * @param {JSX.Element} props.children - The children
 * @returns {JSX.Element} A react component
 */
export function FanPageProvider({ children, fanPageId, ...props }) {
    return (
        <FanPageContext.Provider
            value={{
                ...props,
            }}
        >
            {children}
        </FanPageContext.Provider>
    );
}
