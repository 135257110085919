import React, { useState } from 'react';
import { Modal } from '@mantine/core';
import { subscriptionService } from '@resources/services';
import { Plan } from './Plan';

import './PlansModal.scss';
import { t } from 'i18next';

export function PlansModal({ id, hideModal }) {
    const [coupon] = useState(window.Rewardful?.coupon || {});

    function handleSelectPlan(priceId) {
        subscriptionService
            .getStripeCheckoutLink({
                price_id: priceId,
            })
            .then((res) => {
                window.open(res.data.url, '_blank');
            });
    }

    const labeling = t('massive');
    const pages = t('pages');

    let plans = {
        starter: 'price_1MKsBqDVJ9D2bYPZNXL6l1UR',
        small: 'price_1MKsHCDVJ9D2bYPZmkfhHyw6',
        marketer: 'price_1MKsNVDVJ9D2bYPZ1LEm8rdj',
        unlimited: 'price_1MKsSUDVJ9D2bYPZItRwxdVf',
    };

    if (process.env.NODE_ENV !== 'production') {
        plans = {
            starter: 'price_1P5kpvDVJ9D2bYPZROjWyl4U',
            small: 'price_1P5jn5DVJ9D2bYPZvW8PmUBQ',
            marketer: 'price_1P5jslDVJ9D2bYPZ0f5V9JfK',
            unlimited: 'price_1P5kodDVJ9D2bYPZNH71OZV7',
        };
    }

    return (
        <>
            <Modal.Root opened={true} onClose={hideModal} size="1200px">
                <Modal.Overlay color="gray" opacity="0.55" blur="3" />
                <Modal.Content>
                    <div className="py-4 px-11 text-center">
                        <h1 className="text-2xl font-bold mb-0">{t('plans')}</h1>
                        <p className="text-gray-500 mt-0">{t('choose_your_plan')}</p>

                        {coupon?.id && (
                            <div className="my-4 bg-[#ffd55c] text-black py-2 text-center border-b-3 border-yellow-400">
                                {t('referal_discount')}
                            </div>
                        )}
                        <div className="plans">
                            <Plan
                                title="STARTER"
                                price="14.99"
                                specs={{
                                    massiveMessages: '2 000',
                                    massiveReplies: '2 000',
                                    pages,
                                    labeling,
                                }}
                                onSelectPlan={() =>
                                    handleSelectPlan(plans.starter)
                                }
                            />

                            <Plan
                                highlight={true}
                                title="SMALL BUSINESS"
                                price="29.99"
                                priceId="test"
                                specs={{
                                    massiveMessages: '10 000',
                                    massiveReplies: '10 000',
                                    pages,
                                    labeling,
                                }}
                                onSelectPlan={() =>
                                    handleSelectPlan(plans.small)
                                }
                            />

                            <Plan
                                title="MARKETER"
                                price="49.99"
                                priceId="test"
                                specs={{
                                    massiveMessages: '50 000',
                                    massiveReplies: '50 000',
                                    pages,
                                    labeling,
                                }}
                                onSelectPlan={() =>
                                    handleSelectPlan(plans.marketer)
                                }
                            />

                            <Plan
                                title="UNLIMITED"
                                price="99.99"
                                priceId="test"
                                specs={{
                                    massiveMessages: 'Unlimited',
                                    massiveReplies: 'Unlimited',
                                    pages,
                                    labeling,
                                }}
                                onSelectPlan={() =>
                                    handleSelectPlan(plans.unlimited)
                                }
                            />
                        </div>
                        <div className="text-left mt-4 text-gray-400">
                            <span className="text-[red]">*</span> {t('the_number_message')}

                        </div>
                    </div>
                </Modal.Content>
            </Modal.Root>
        </>
    );
}
