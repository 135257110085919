//urdu de pakistan ur
export default {
    translations: {
        search: 'تلاش کریں',
        social_inbox_marketing_is_born: 'سوشل ان باکس مارکیٹنگ پیدا ہوتی ہے',
        login_with_facebook: 'فیس بک کے ساتھ لاگ ان کریں',
        logout: 'لاگ آؤٹ',
        login_description: `
            <strong>بھیجیں</strong> پیغامات
            <strong>ان باکس</strong> کی طرف سے
            <strong>ہزاروں لوگوں کو</strong> جو پہلے ہی
            آپ کی صفحے کے ساتھ بات چیت کر چکے ہیں۔
            <br />
            <br />
            وہ تگاں منتخب کریں جو آپ چاہتے ہیں اور پیغام، خبریں یا
            جو کچھ بھی آپ شیئر کرنا چاہتے ہیں، بھیجیں۔
        `,
        search_page: 'صفحہ تلاش کریں...',
        people_like_this: '{{ لوگوں }} کو یہ پسند ہے',
        view_page: 'صفحہ دیکھیں',
        add_a_new_page: 'نیا صفحہ شامل کریں',
        support: 'مدد چاہیے؟',
        earn_money_with_us: 'ہمارے ساتھ پیسے کمائیں',
        learn_more: 'مزید جانیں',
        available_messages: 'آپ کے پاس {{ لوگ }} پیغامات موجود ہیں۔',
        need_more_messages: 'آپ کو مزید پیغامات کی ضرورت ہے؟',
        messages: 'پیغامات',
        search_and_filter_conversations: 'بات چیت تلاش اور فلٹر کریں',
        loading: 'لوڈ ہو رہا ہے',
        loading_chat_messages: 'چیٹ پیغامات لوڈ ہو رہے ہیں',
        loading_all_recipients: 'تمام موصولین لوڈ ہو رہے ہیں',
        send_message: 'پیغام بھیجیں',
        send_bulk_message: 'نیا بلک پیغام بھیجیں',
        no_such_more_messages: ' کوئی مزید پیغام نہیں ہیں',
        write_your_message: 'اپنا پیغام لکھیں',
        upload_image: 'تصویر اپ لوڈ کریں',
        incomplete_form: 'غیر مکمل فارم',
        message_required: 'براہ کرم ایک پیغام درج کریں',
        you_have_no_messages: 'آپ کے پاس کوئی پیغامات موجود نہیں ہیں',
        please_purchase_a_subscription:
            'براہ کرم پیغامات بھیجنے کے لئے اشتراک خریدیں',
        buy_a_subscription: 'اشتراک خریدیں',
        buy_a_addon: 'ایڈون خریدیں',
        sending_message: 'پیغام بھیجنا',
        sending_messages_description:
            '{{ لوگوں }} موصولین کو پیغام بھیج رہا ہے۔',
        step: 'مرحلہ {{ مرحلہ }}',
        select_audience: 'اپنا حاضرین منتخب کریں',
        select_fb_tag: 'آپ کے پیغام کی مواد کی قسم منتخب کریں',
        select_audience_description1:
            'پیغام کو کس کو بھیجنا ہے، اس کو منتخب کریں',
        select_audience_description2:
            'صفحے کی ان باکس میں سب کو منتخب کرنے کے لئے "تمام موصولین" استعمال کریں',
        select_audience_description3:
            'آپ بھی آدمیوں کو خارج کرنے کے لئے لیبلز استعمال کرسکتے ہیں',
        labels: 'لیبلز',
        recipients: 'موصولین',
        all_recipients: 'تمام موصولین',
        no_label: 'کوئی لیبل نہیں',
        next_step: 'اگلا مرحلہ',
        select_fb_tag_description:
            'آپ کی صفحے کی ان باکس میں وہ لوگ ہیں جو 24 گھنٹوں سے زیادہ پہلے تعامل کر چکے ہیں',
        tag_confirmed_event_update: 'تصدیق شدہ واقعہ تازہ کاری',
        tag_confirmed_event_update_description: `
            ان لوگوں کے لئے جو کسی تقریب کی تصدیق کر چکے ہیں۔
        `,
        tag_post_purchase_update: 'خرید کے بعد تازہ کاری',
        tag_post_purchase_update_description: `
            ان لوگوں کے لئے جو آپ کی ایپلیکیشن میں خرید کی ہے۔
        `,
        tag_account_update: 'اکاؤنٹ تازہ کاری',
        tag_account_update_description: `
            ان لوگوں کے لئے جو آپ کی ایپلیکیشن میں رجسٹرڈ اکاؤنٹ رکھتے ہیں۔
        `,
        select_fb_tag_footer_description: `
            ان قسم کی مواد کو منتخب کریں تاکہ
            ان سے متعلق پیغامات بھیج سکیں۔ ان کی کوئی بھی قسم کی موادات بھیجنا
            آپ کی Facebook™ صفحے کی پابندی کا سبب بن سکتی ہے۔
            <strong>
                ان قسم کی مواد کا استعمال کر کے پروموشن بھیجیں اپنی مخاطرے پر کریں۔
            </strong>
        `,
        more_info: 'مزید معلومات',
        write_your_message_and_send: 'اپنا پیغام لکھیں اور بھیجیں',
        this_message_will_be_sent_with_a_watermark:
            "یہ پیغام reply'em کے ساتھ ایک واٹرمارک کے ساتھ بھیجا جائے گا، اگر آپ اپنے پیغام کو ہمارے واٹرمارک کے بغیر بھیجنا چاہتے ہیں تو آپ ایک اشتراک خرید سکتے ہیں۔",
        this_messsage_will_be_sent_only_to:
            'یہ پیغام صرف {{ لوگ }} موصولین کو بھیجا جائے گا۔',
        messages_rejected:
            '{{ پیغامات_منظور_نہیں_ہوئے }} پیغامات منظور نہیں ہوئے',
        messages_sent_successfully: `
            <strong>{{ پیغامات_بھیجے_گئے }}</strong> پیغامات بھیجے گئے،
            <strong>{{ خرابی_والے_پیغامات }}</strong> منظور نہیں ہوئے۔
        `,
        messages_in_progress: `جاری ہے <strong>{{ پیغامات_بھیجے_گئے }}</strong>/<strong>{{ کل_پیغامات }}</strong> پیغامات بھیجنا۔`,
        affiliates: {
            title: 'ساتھیوں',
            subtitle: 'ہمارے ساتھ پیسے کمائیں',
            how_it_works: 'یہ کس طرح کام کرتا ہے؟',
            how_it_works_description: `
                آپ دوستوں، خاندان یا کسی بھی کو دعوت دیکر پیسے کما سکتے ہیں،
                <strong class="text-primary">
                    آپ کو آپ کے رجحانے والوں کی طرف سے 2 مہینوں کے دوران کی ہر خریداری کا 20% ملے گا
                </strong>
                ، آپ کے رجحانے والے کو 3 مہینوں کے لئے کسی بھی منصوبے پر 15% کا چھٹا ملے گا۔
            `,
            sign_up: 'سائن اپ کریں',
            sign_up_description: `
                پیسے کمانے کے لئے شروع ہونے کے لئے آپ کو صرف ہمارے میں شامل ہونا ہوتا ہے۔
            `,
            invites: 'دعوت دیں',
            invites_description: `
                آپ سب دوستوں، خاندان یا کسی بھی کو دعوت دیں کہ وہ ریپلائیم کا استعمال کریں
                ہمارے حوالے سے ہونے والے رجحانے کے پروگرام کے تھروں کے ساتھ بنائیں گے۔
            `,
            earn_money: 'پیسے کمائیں',
            earn_money_description:
                'ہمارے ساتھ آسانی سے پیسے کمانے کا آغاز کریں',
            start_earning_money_now: 'ابھی پیسے کمانا شروع کریں',
            terms: 'شرائط',
            terms_description: `
                آپ پہلی ادائیگی کی درخواست کر سکتے ہیں جب تک آپ کی کمی زیریں ترجیحی حد $10 تک نہیں پہنچتی۔ ادائیگی پیپال کے ذریعے کی جائیگی۔ علاوہ ازیں، آپ کے پیشے کے امتیاز کے تمام خریداریوں پر 20% کمیشن کما سکتے ہیں جو ان کے صارفین نے ان کی رجسٹریشن کے پہلے 60 دنوں میں کی ہے۔ آپ اپنی کمائی کو مندرجہ ذیل لنک پر پیچھا کر سکتے ہیں
            `,
        },
        popular: 'مقبول',
        massive_messages: 'بڑے پیغامات',
        massive_replies: 'بڑے جوابات',
        pages: 'صفحات',
        labeling: 'لیبلنگ',
        choose_plan: 'پلان منتخب کریں',
        choose_your_plan: 'اپنا پلان منتخب کریں',
        rederal_discount: 'رفرل ڈسکائونٹ',
        massive: 'بڑے',
        month: 'مہینہ',
        the_number_message: 'پیغامات کی تعداد ماہانہ ہے',
        plans: 'پلانز',
        union_y: 'اور',
        plans_page: {
            title: 'آپ کا منصوبہ ختم ہوگیا ہے۔',
            description: `فکر نہ کریں، آپ ایک منصوبہ منتخب کر سکتے ہیں اور
                جاری رہنے والے فوائد کا لطف اٹھا سکتے ہیں جو reply'em فراہم کرتا ہے۔`,
            mistake: 'اگر آپ کے پاس ایک فعال منصوبہ ہے',
            link_mistake: 'ہم سے رابطہ کریں',
            referral_discount: 'ریفرل چھوٹ دسکونٹ اگلے صفحے پر دکھایا جاتا ہے۔',
            starter_header: 'آغازی',
            pequeno_negocio: 'چھوٹا کاروبار',
            marketer_bussines: 'مارکیٹر',
            ilimitado: 'بے حد',
            starter_price_annually:
                'یا {{ priceStarterAnnualy }} سالانہ ادائیگی کرتے وقت',
            pequeno_negocio_price_annually:
                'یا {{ priceNegocioAnnualy }} سالانہ ادائیگی کرتے وقت',
            marketer_price_annually:
                'یا {{priceMarketerAnnualy}} سالانہ ادائیگی کرتے وقت',
            unlimited_price_annually:
                'یا {{priceUnlimitedAnnualy}} سالانہ ادائیگی کرتے وقت',
            months: '/مہینے',
            unlimited: 'بے حد',
            suscribe: 'سبسکرائب کریں',
        },
    },
};
