import { PAGE_CUSTOMERS } from '../actions/types';

const initialState = {
    loading: false,
    customers: [],
    afterToken: null,
    isLastPage: false,
    filter: '',
};

export function pageCustomersReducer(state = initialState, action) {
    const {type, payload} = action;

    switch (type) {
        case PAGE_CUSTOMERS.SET:
            return {
                ...state,
                ...payload,
            };
        case PAGE_CUSTOMERS.LOADING:
            return {
                ...state,
                loading: true,
            };
        case PAGE_CUSTOMERS.LOADED:
            return {
                ...state,
                loading: false,
            };
        case PAGE_CUSTOMERS.FILTER:
                return {
                    ...state,
                    filter: payload,
                };
        case PAGE_CUSTOMERS.RESET:
            return initialState;
        default:
            return state;
    }
}
