import React, { useEffect, useState } from 'react';
import { t } from 'i18next';
import { Alert, Box, LoadingOverlay, Modal } from '@mantine/core';
import { useAuth, useGlobalModals } from '@resources/contexts';
import { UserAvatar } from '@features/components';
import { UserTabs } from './UserTabs';
import { userService } from "@resources/services";
import dayjs from "dayjs";
import { CancelSubscriptionModal } from "../CancelSubscriptionModal";

export function UserModal({ id, hideModal }) {
    const { fbUser } = useAuth();
    const { showModal } = useGlobalModals();
    const [userSubscriptions, setUserSubscriptions] = useState(null);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true);
        userService.showMySubscription()
            .then((subscriptions) => setUserSubscriptions(subscriptions))
            .finally(() => setLoading(false));
    }, []);

    function handleShowCancelSubscriptionModal() {
        hideModal(id);
        showModal(<CancelSubscriptionModal/>);
    }

    return (
        <Modal.Root opened={true} onClose={hideModal} size="600px">
            <Modal.Overlay color="gray" opacity="0.55" blur="3"/>
            <Modal.Content className="bg-transparent overflow-hidden shadow-none">
                <div className="p-8 bg-white shadow mt-24 min-h-[350px]">
                    <Box pos="relative">
                        <LoadingOverlay visible={loading}/>
                        <div className="grid grid-cols-1 md:grid-cols-3">
                            <div className="order-last md:order-first mt-20 md:mt-0">
                                <div className="text-md">
                                    <p className="font-bold text-gray-700">
                                        {fbUser.name}
                                    </p>
                                    <p className="text-gray-400 text-sm">
                                        Id: {fbUser.id}
                                    </p>
                                </div>
                            </div>
                            <div className="relative">
                                {!loading && <UserAvatar
                                    className="w-48 h-48 bg-indigo-100 mx-auto rounded-full shadow-2xl absolute inset-x-0 top-0 -mt-24 flex items-center justify-center"/>}
                            </div>

                            <div className="text-right mt-32 md:mt-0">
                                <p className="font-bold text-gray-700">
                                    {userSubscriptions && dayjs(userSubscriptions.current_period_end).format('LL')}
                                </p>
                                <p className="text-gray-400 text-sm">
                                    {t('user_modal.subscription_end')}
                                </p>

                                <p className="mt-2">
                                    {
                                        userSubscriptions && !userSubscriptions.cancel_at_period_end &&
                                        <button
                                            className="text-white bg-red-700 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2"
                                            onClick={handleShowCancelSubscriptionModal}
                                        >
                                            {t('user_modal.cancel_subscription')}
                                        </button>
                                    }
                                </p>
                            </div>

                        </div>
                        <div className="mt-[50px]">
                            {
                                userSubscriptions && userSubscriptions.cancel_at_period_end &&
                                <Alert variant="filled" color="orange" className="mb-4">
                                    The current subscription will be automatically canceled at the end of the period.
                                </Alert>
                            }
                            <h3 className="text-2xl text-gray-700">{t('user_modal.list_subscriptions')}</h3>
                            {userSubscriptions && (
                                <div className="capitalize">
                                    <p>
                                        <span className="font-bold text-gray-600">{t('plan')}: </span>
                                        <span className="font-light">
                                            {userSubscriptions.product ?
                                                userSubscriptions.product.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()).join(' ') :
                                                t('user_modal.amount')
                                            } ({userSubscriptions.amount} USD)
                                        </span>
                                    </p>

                                    <p>
                                        <span className="font-bold text-gray-600">{t('since')}: </span>
                                        <span
                                            className="font-light">{dayjs(userSubscriptions.created).format('LL')}</span>
                                    </p>
                                </div>
                            )}
                        </div>
                    </Box>
                </div>
            </Modal.Content>
        </Modal.Root>
    );
}
