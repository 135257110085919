import { useEffect, useRef, useState } from 'react';
import { MESSAGE } from '@resources/constants';
import { facebookService, messageService } from '@resources/services';
import { notifications } from '@mantine/notifications';
import { useDispatch } from 'react-redux';
import { AUTH } from 'resources/actions/types';
import { sleep } from 'resources/helpers';

export function useSingleBulkMessage(bulkMessage) {
    const { id, customers: messages, page } = bulkMessage;
    const dispatch = useDispatch();

    const active = useRef();
    const [sent, setSent] = useState(false);
    const [messagesSent, setMessagesSent] = useState(0);
    const [totalMessages, setTotalMessages] = useState(0);
    const [errorMessages, setErrorMessages] = useState(0);

    useEffect(() => {
        setTotalMessages(messages.length);

        active.current = true;

        startSendMessages();

        return () => {
            active.current = false;
        };
    }, [id]);

    async function startSendMessages() {
        if (!active.current || sent) return;

        for (const message of messages) {
            const {
                participants: {
                    data: [customer],
                },
            } = message;

            // Generates a random number between 800ms and 1500ms using Math.random()
            const randomTime = Math.floor(
                Math.random() * (1500 - 800 + 1) + 800
            );

            await sleep(randomTime);
            await sendMessage(customer);
        }

        active.current = false;

        setSent(true);
    }

    async function sendMessage({ id: customerId, name: customerName }) {
        facebookService
            .sendMessage({
                recipientId: customerId,
                pageAccessToken: page.access_token,
                imageUrl: bulkMessage.image,
                message: bulkMessage.message,
                facebookTag: bulkMessage.facebookTag,
            })
            .then(() => {
                messageService
                    .touchMessage(id, MESSAGE.STATUS.OK)
                    .then((res) => {
                        dispatch({
                            type: AUTH.SET_SUBSCRIPTION,
                            payload: {
                                available_messages:
                                    res?.data?.available_messages,
                            },
                        });
                    });
            })
            .catch((error) => {
                console.error(error);
                setErrorMessages((errorMessages) => errorMessages + 1);

                messageService.touchMessage(id, MESSAGE.STATUS.NG, {
                    error_message: JSON.stringify(error),
                    recipent_id: customerId,
                    recipent_name: customerName,
                });

                if (error?.error?.message) {
                    notifications.show({
                        title: 'Message rejected',
                        message: error?.error?.message,
                        color: 'red',
                        autoClose: false,
                        loading: false,
                    });
                }
            })
            .finally(() => {
                setMessagesSent((messagesSent) => messagesSent + 1);
            });
    }

    return {
        sent,
        messagesSent,
        errorMessages,
        totalMessages,
    };
}
