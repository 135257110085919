import React, { useState } from 'react';
import { useAuth } from '@resources/contexts';
import { PageCard } from './PageCard';
import { NewPageCard } from './NewPageCard';
import { t } from 'i18next';

/**
 * Home
 * The home page component
 *
 * @returns {JSX.Element} A react component
 */
export function Home() {
    const [search, setSearch] = useState('');
    const { fbUser } = useAuth();

    function handleSearch(event) {
        setSearch(event.target.value);
    }

    const pages = (fbUser?.accounts?.data || [])
        .sort((a, b) => !!b.access_token - !!a.access_token)
        .filter((page) =>
            page.name.toLowerCase().includes(String(search).toLowerCase())
        );

    return (
        <div className="block px-4 py-8 bg-white shadow-lg shadow-slate-200">
            <div className="mb-4">
                <div className="relative">
                    <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                        <svg
                            aria-hidden="true"
                            className="w-5 h-5 text-gray-500"
                            fill="none"
                            stroke="currentColor"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                            ></path>
                        </svg>
                    </div>
                    <input
                        type="search"
                        id="default-search"
                        onChange={handleSearch}
                        className="block w-full p-4 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500"
                        placeholder={t('search_page')}
                    />
                    <button
                        type="submit"
                        className="text-white absolute right-2.5 bottom-2.5 bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2"
                    >
                        {t('search')}
                    </button>
                </div>
            </div>
            <div className="grid grid-cols-2 md:grid-cols-4 lg:grid-cols-5 gap-4">
                {pages.map((page) => (
                    <PageCard key={page.id} page={page} />
                ))}

                <NewPageCard />
            </div>
        </div>
    );
}
