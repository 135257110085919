import React from 'react';
import axios from 'axios';
import { Provider } from 'react-redux';
import ReactDOM from 'react-dom/client';
import dayjs from 'dayjs';
import { Notifications } from '@mantine/notifications';
import relativeTime from 'dayjs/plugin/relativeTime';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';
import { Router } from '@features/Router';
import { Compose, LoadingWrapper } from '@features/components';
import {
    AppProvider,
    AuthProvider,
    GlobalModalsProvider,
} from '@resources/contexts';
import { setupAxios } from '@resources/helpers/setupAxios';
import store from '@resources/store';
import '@resources/i18n';

require('dayjs/locale/de');
require('dayjs/locale/es');

import './index.scss';

setupAxios(axios);

dayjs.extend(relativeTime);
dayjs.extend(LocalizedFormat);
dayjs.locale('en');

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <Provider store={store}>
        <Compose components={[AppProvider, AuthProvider, GlobalModalsProvider]}>
            <LoadingWrapper>
                <Router/>
            </LoadingWrapper>
        </Compose>
        <Notifications position="top-center" zIndex={2077}/>
    </Provider>
);
