import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useScroll, useSearch } from '@resources/hooks';
import { useFanPage } from '@resources/contexts';
import { customerSelectedActions, pageActions } from '@resources/actions';
import { CustomerMessageCover } from './CustomerMessageCover';
import { t } from 'i18next';

export function MessengerMenuBody() {
    const dispatch = useDispatch();
    const { customersStore, setShowBulkMessageWizard } = useFanPage();

    const { recordsFiltered: customersFiltered } = useSearch(
        customersStore.customers,
        {
            keys: ['snippet', 'participants.data.name'],
        },
        customersStore.filter
    );

    const { scrollRefElement } = useScroll({
        onScrollEnd: useCallback(
            () => dispatch(pageActions.getPageCustomers()),
            [customersStore.isLastMessage, customersStore.loading]
        ),
    });

    function handleSelectUser(customerMessage) {
        setShowBulkMessageWizard(false);
        dispatch(customerSelectedActions.selectCustomer(customerMessage));
    }

    function handleSelectedChange() {
        // TODO
    }

    return (
        <div
            className="relative mx-auto bg-white flex-1 overflow-y-auto w-full"
            ref={scrollRefElement}
        >
            <div className="pl-5">
                <div className="divide-y divide-gray-200">
                    {customersFiltered.map((customerMessage) => (
                        <CustomerMessageCover
                            key={customerMessage.id}
                            customerMessage={customerMessage}
                            onClick={() => handleSelectUser(customerMessage)}
                            onSelectedChange={handleSelectedChange}
                            active={false}
                        />
                    ))}
                    {customersStore.loading ? (
                        <div className="flex justify-center items-center h-20">
                            <div className="text-gray-400 text-center">
                                <div className="text-sm">{t('loading')}...</div>
                            </div>
                        </div>
                    ) : (
                        (customersStore.isLastMessage ||
                            customersFiltered.length === 0) && (
                            <div className="flex justify-center items-center h-20">
                                <div className="text-gray-400 text-center">
                                    <div className="text-sm">
                                        {t('no_such_more_messages')}
                                    </div>
                                </div>
                            </div>
                        )
                    )}
                </div>
            </div>
        </div>
    );
}
