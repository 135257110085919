export default {
    translations: {
        search: 'Buscar',
        social_inbox_marketing_is_born: 'Social Inbox Marketing nace',
        login_with_facebook: 'Continuar con Facebook',
        logout: 'Cerrar sesión',
        login_description: `
            <strong>Envía</strong> mensajes de
            <strong>inbox</strong> dirigidos por
            <strong>miles a las personas</strong> que ya han
            iniciado una conversación con tu página.
            <br />
            <br />
            Selecciona las etiquetas que quieras y envía una promoción, noticias o
            lo que quieras compartir.
        `,
        search_page: 'Buscar página...',
        people_like_this: 'A {{ people }} les gusta esto',
        view_page: 'Ver página',
        add_a_new_page: 'Añadir una nueva página',
        support: '¿Necesitas ayuda?',
        earn_money_with_us: 'Gana dinero con nosotros',
        learn_more: 'Más información',
        available_messages: 'Tienes {{ people }} mensajes disponibles.',
        need_more_messages: '¿Necesitas más mensajes?',
        messages: 'Mensajes',
        search_and_filter_conversations: 'Buscar y filtrar conversaciones',
        loading: 'Cargando',
        loading_chat_messages: 'Cargando mensajes de chat',
        loading_all_recipents: 'Cargando todos los destinatarios',
        send_message: 'Enviar mensaje',
        send_bulk_message: 'Nuevo mensaje masivo',
        no_such_more_messages: ' No hay más mensajes',
        write_your_message: 'Escribe tu mensaje',
        upload_image: 'Subir imagen',
        incomplete_form: 'Formulario incompleto',
        message_required: 'Por favor, introduzca un mensaje',
        you_have_no_messages: 'No tienes mensajes disponibles',
        please_purchase_a_subscription:
            'Por favor, compra una suscripción para enviar mensajes',
        buy_a_subscription: 'Comprar plan',
        buy_a_addon: 'Comprar Addon',
        sending_message: 'Enviando mensaje',
        sending_messages_description:
            'Enviando mensaje a {{people}} destinatarios.',
        step: 'Paso {{step}}',
        select_audience: 'Selecciona tu audiencia',
        select_fb_tag: 'Selecciona el tipo de contenido de tu mensaje',
        select_audience_description1:
            'Selecciona etiquetas para elegir a quién enviar un mensaje',
        select_audience_description2:
            'Usa "todos los destinatarios" para seleccionar a todos en tu bandeja de entrada de la página',
        select_audience_description3:
            'También puedes usar etiquetas para excluir personas',
        labels: 'Etiquetas',
        recipents: 'Destinatarios',
        all_recipients: 'Todos los destinatarios',
        no_label: 'Sin etiqueta',
        next_step: 'Siguiente paso',
        select_fb_tag_description:
            'Para las personas en tu bandeja de entrada que interactuaron hace más de 24 horas',
        tag_confirmed_event_update: 'Confirmación de actualización de evento',
        tag_confirmed_event_update_description: `
            Para personas que confirmaron asistencia a un evento.
        `,
        tag_post_purchase_update: 'Actualización de compra posterior',
        tag_post_purchase_update_description: `
            Para personas que han realizado una compra en tu aplicación.
        `,
        tag_account_update: 'Actualización de cuenta',
        tag_account_update_description: `
            Para personas que tienen cuentas registradas en tu aplicación.
        `,
        select_fb_tag_footer_description: `
            Seleccionar este tipo de tipos de contenido para enviar
            Los mensajes que no pertenecen a estas categorías pueden
            resultará en que Facebook™ suspenda su página.
            <fuerte>
                ENVÍE PROMOCIONES UTILIZANDO LOS TIPOS DE CONTENIDO ANTERIORES A
                SU PROPIO RIESGO.
            </strong>
        `,
        more_info: 'Más información',
        write_your_message_and_send: 'Escribe tu mensaje y envíalo',
        this_message_will_be_sent_with_a_watermark: `Este mensaje se enviará con una marca de agua de reply'em, si
        desea enviar su mensaje sin nuestra marca de agua
        puede adquirir una suscripción.`,
        this_messsage_will_be_sent_only_to:
            'Este mensaje sólo se enviará {{people}} destinatarios.',
        messages_rejected: '{{ messagesRejected }} Mensajes rechazados',
        messages_sent_successfully: `
            <strong>{{ messagesSent }}</strong> mensajes enviados,
            <strong>{{ errorMessages }}</strong> rechazados.
        `,
        messages_in_progress: `Enviando <strong>{{ messagesSent }}</strong>/<strong>{{ totalMessages }}</strong> mensajes.`,
        affiliates: {
            title: 'Afiliados',
            subtitle: 'Gana dinero con nosotros',
            how_it_works: 'Comó funciona?',
            how_it_works_description: `
                Puedes ganar dinero invitando a amigos, familiares o quien sea,
                <strong class="text-primary">
                    Vas a recibir el 20% de todas las compras que haga tu referido durante 2 meses
                </strong>
                , tus referidos van a recibir un descuento del 15% por 3 meses en cualquier plan.`,
            sign_up: 'Sign up',
            sign_up_description: `
                Para empezar a ganar dinero, solo tienes que registrarte en nuestro programa de afiliados.
            `,
            invites: 'Invita',
            invites_description: `
                Invita a todos tus amigos, familiares o cualquier otra persona a utilizar
                replyem con el enlace que generaremos en el programa de referidos
                programa.`,
            earn_money: 'Gana dinero',
            earn_money_description:
                'Empieza a ganar dinero con nosotros así de fácil',
            start_earning_money_now: 'Empieza a ganar dinero ahora',
            terms: 'Términos',
            terms_description: `
                Puedes solicitar tu primer pago hasta que alcances un saldo mínimo de 10 dólares. Los pagos se procesarán a través de
                PayPal. Además, tienes la oportunidad de ganar un 20%
                comisión sobre todas las compras realizadas por tus usuarios en los primeros 60 dias desde su registro. Puedes
                darle seguimiento a todas tus ganancias en el siguiente enlace
            `,
        },
        popular: 'Popular',
        massive_messages: 'Mensajes masivos',
        massive_replies: 'Respuestas masivas',
        pages: 'Paginas',
        labeling: 'Etiquetado',
        choose_plan: 'Elige plan',
        choose_your_plan: 'Elige tu plan',
        rederal_discount: 'Descuento de referido',
        massive: 'Masivos',
        month: 'Mes',
        the_number_message: 'El numero de mensajes es mensual',
        plans: 'Planes',
        union_y: 'y',
        plans_page: {
            title: 'Tu plan expiró.',
            description: `No te preocupes tu puedes elegir un plan y
                seguir disfrutando de los beneficios que ofrece reply'em`,
            mistake: 'Si cuentas con un plan vigente',
            link_mistake: 'conctactanos',
            referral_discount:
                'El descuento por referido se muestra en la siguiente pagina',
            starter_header: 'Inicial',
            pequeno_negocio: 'Pequeño negocio',
            marketer_bussines: 'Marketero',
            ilimitado: 'Ilimitado',
            starter_price_annually:
                'o {{ priceStarterAnnualy }} al pagar por año',
            pequeno_negocio_price_annually:
                'o {{ priceNegocioAnnualy }} al pagar por año',
            marketer_price_annually:
                'o {{priceMarketerAnnually}} al pagar por año',
            unlimited_price_annually:
                'o {{priceUnlimitedAnnually}} al pagar por año',
            months: '/mes',
            unlimited: 'Ilimitados',
            suscribe: 'Suscribete',
        },
    },
};
