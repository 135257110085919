import React, { useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { Sidebar } from './Sidebar';
import { FacebookSupportFloatButton } from './FacebookSupportFloatButton';
import { useAuth } from 'resources/contexts';
import { AffiliatesBanner } from './AffiliatesBanner';

/**
 * Replyem Layout
 * The principal layout of the app
 *
 * @returns A react component
 */
export function ReplyemLayout() {
    const { subscription } = useAuth();
    const navigation = useNavigate();

    useEffect(() => {
        if (!subscription.access) {
            navigation('/plans');
        }
    }, []);

    return (
        <>
            <Sidebar />

            <div className="container float-left ml-16">
                <AffiliatesBanner />

                <div className="mt-4">
                    <Outlet />
                </div>
            </div>

            <FacebookSupportFloatButton />
        </>
    );
}
