export const sdk = {
    permissions: [
        'business_management',
        'pages_messaging',
        'pages_show_list',
        //'read_page_mailboxes',
        //'pages_read_engagement',
        'email',
    ],

    init() {
        return new Promise((resolve) => {
            if (typeof window.FB !== 'undefined') {
                resolve();
            } else {
                window.fbAsyncInit = () => {
                    window.FB.init({
                        appId: process.env.REACT_APP_FB_APP_ID,
                        status: true,
                        cookie: true,
                        xfbml: true,
                        version: process.env.REACT_APP_FB_API_VERSION,
                    });
                    resolve();
                };
                (function (d, s, id) {
                    var js,
                        fjs = d.getElementsByTagName(s)[0];
                    if (d.getElementById(id)) return;
                    js = d.createElement(s);
                    js.id = id;
                    js.src =
                        process.env.NODE_ENV === 'production'
                            ? '//connect.facebook.net/en_US/sdk.js'
                            : '//connect.facebook.net/en_US/sdk/debug.js';
                    fjs.parentNode.insertBefore(js, fjs);
                })(document, 'script', 'facebook-jssdk');
            }
        });
    },

    login() {
        return new Promise((resolve, reject) => {
            window.FB.login(
                (res) => sdk.processLoginResponse(res)(resolve, reject),
                {
                    scope: sdk.permissions.join(),
                    auth_type: 'rerequest',
                }
            );
        });
    },

    logout() {
        return new Promise((resolve, reject) => {
            window.FB.logout(resolve);
        });
    },

    checkAuthState() {
        return new Promise((resolve, reject) => {
            window.FB.getLoginStatus((res) =>
                sdk.processLoginResponse(res)(resolve, reject)
            );
        });
    },

    processLoginResponse: (res) => async (resolve, reject) => {
        if (res.status !== 'connected') {
            return reject({
                status: false,
            });
        }

        const user = await sdk.getUserInfo(res.authResponse.accessToken);

        if (!sdk.checkPermissionsStatus(user.permissions.data)) {
            return reject({
                status: false,
            });
        }

        return resolve({
            status: true,
            user,
            access_token: res.authResponse.accessToken,
        });
    },

    async getUserInfo(access_token) {
        return this.api({
            endpoint: 'me',
            params: {
                fields: 'id,name,picture.type(large),gender,email,permissions{status,permission},accounts.limit(500){id,name,access_token,category,picture.width(250){url},cover{source},unread_message_count,unread_notif_count,fan_count}',
                access_token,
            },
        });
    },

    async api({ endpoint, method = 'GET', params = {} }) {
        return new Promise((resolve, reject) => {
            return window.FB.api(endpoint, method, params, (response) => {
                if (!response || response.error) {
                    // TODO - notify the user
                    // TODO - notify the server
                    reject(response);
                } else {
                    resolve(response);
                }
            });
        });
    },

    checkPermissionsStatus(userPermissions) {
        const permissions = userPermissions
            .filter((permission) => permission.status === 'granted')
            .map((permission) => permission.permission);

        const hasAllPermissions = !sdk.permissions.filter(
            (permission) => !permissions.includes(permission)
        ).length;

        return hasAllPermissions;
    },
};
