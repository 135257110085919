import React from 'react';
import { authActions } from '@resources/actions';

import './login.scss';
import { useDispatch } from 'react-redux';
import { t } from 'i18next';

/**
 * Login
 * The Login page component
 *
 * @returns {JSX.Element} A react component
 */
export function Login() {
    const dispatch = useDispatch();

    function handleLogin() {
        dispatch(authActions.login());
    }

    return (
        <div className="login-page">
            <div className="flex items-center justify-center h-screen">
                <div className="bg-white rounded border shadow-lg shadow-black/50 p-10 max-w-xs text-center text-slate-600 text-[14px] z-10">
                    <img src="/assets/imgs/logo-big.png" />
                    <h5 className="uppercase text-[10px] my-3">
                        {t('social_inbox_marketing_is_born').toUpperCase()}
                    </h5>
                    <div className="mb-4">
                        <div
                            dangerouslySetInnerHTML={{
                                __html: t('login_description'),
                            }}
                        />
                    </div>

                    <button className="btn btn-primary" onClick={handleLogin}>
                        <span>
                            <i className="fa fa-facebook-square mr-1"></i>{' '}
                            {t('login_with_facebook')}
                        </span>
                    </button>
                </div>
            </div>
        </div>
    );
}
