import axios from 'axios';

export const messageService = {
    store(messageData) {
        return axios.post('messages', messageData).then((res) => res.data);
    },

    touchMessage(messageId, status, errorData = {}) {
        return axios
            .post(`messages/${messageId}/touch`, {
                _method: 'PUT',
                status,
                error_data: errorData,
            })
            .then((res) => res.data);
    },
};
