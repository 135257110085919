import axios from 'axios';

export const subscriptionService = {
    getStripeCheckoutLink(params) {
        return axios
            .post(`user/subscriptions/checkout`, {
                success_url: 'https://app.replyem.com/?success=true',
                cancel_url: 'https://app.replyem.com/?success=false',
                referral: window?.Rewardful?.referral || null,
                coupon: window?.Rewardful?.coupon?.id || null,
                ...params,
            })
            .then((res) => res.data);
    },
    cancelSubscription(data) {
        return axios.post(`user/subscriptions/cancel-subscription`, data).then((res) => res.data);
    },
};
