import { Outlet } from 'react-router-dom';
import { Sidebar } from './Sidebar';
import { useFanPage } from '@resources/contexts';

export function FanPageLayout() {
    const { loading } = useFanPage();

    if (loading) {
        return <div></div>;
    }

    return (
        <>
            <div className="bg-[#11569b] absolute w-full top-0	left-0 z-[-1] h-[200px]"></div>
            <div className="flex flex-row gap-4">
                <div className="basis-1/5">
                    <Sidebar />
                </div>
                <div className="basis-4/5">
                    <Outlet />
                </div>
            </div>
        </>
    );
}
