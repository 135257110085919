import { BULK_MESSAGE } from '../actions/types';

const initialState = {
    loading: false,
    customersTotal: 0,
    customers: [],
    afterToken: null,
    isLastPage: false,

    bulkMessages: [],
};

export function bulkMessageReducer(state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case BULK_MESSAGE.SET:
            return {
                ...state,
                ...payload,
            };
        case BULK_MESSAGE.LOADING:
            return {
                ...state,
                loading: true,
            };
        case BULK_MESSAGE.LOADED:
            return {
                ...state,
                loading: false,
            };
        case BULK_MESSAGE.ADD_MESSAGE:
            return {
                ...state,
                bulkMessages: [...state.bulkMessages, payload],
            };
        case BULK_MESSAGE.SET_MESSAGES:
            return {
                ...state,
                bulkMessages: payload,
            };
        case BULK_MESSAGE.RESET_MESSAGES:
            return {
                ...state,
                bulkMessages: [],
            };
        case BULK_MESSAGE.RESET:
            return {
                ...initialState,
                bulkMessages: state.bulkMessages,
            };
        default:
            return state;
    }
}
