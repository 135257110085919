import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import dayjs from 'dayjs';
import { useFanPage } from '@resources/contexts';

export function CustomerMessageCover({
    customerMessage = {},
    active = false,
    onSelectedChange = (value) => {},
    onClick = (value) => {},
}) {
    const [userImage, setUserImage] = useState(null);
    const {
        page: { access_token },
    } = useFanPage();
    const {
        id,
        snippet,
        updated_time,
        participants: {
            data: [customer],
        },
    } = customerMessage;

    useEffect(() => {
        setUserImage(
            `https://graph.facebook.com/${customer.id}/picture?type=small&access_token=${access_token}`
        );
    }, []);

    return (
        <div
            className={classNames({
                'message-preview': true,
                active,
            })}
        >
            <div className="message-options">
                <div className="message-option">
                    <div className="flex items-center w-full">
                        <input
                            defaultChecked={active}
                            type="checkbox"
                            id={id}
                            name="customersSelected"
                            value={id}
                            onChange={() =>
                                onSelectedChange(customerMessage.id)
                            }
                            className="hidden peer"
                        />
                        <label
                            htmlFor={id}
                            className="block w-full text-center text-gray-200 hover:text-gray-400 peer-checked:text-gray-900"
                        >
                            <i className="fa fa-check"></i>
                        </label>
                    </div>
                </div>
            </div>
            <div className="message" onClick={() => onClick(id)}>
                <img
                    className="rounded-full items-start flex-shrink-0 mr-3"
                    onError={() => setUserImage('../assets/imgs/picture.gif')}
                    src={userImage}
                    width="32"
                    height="32"
                    alt={customer.name}
                />
                <div>
                    <h4 className="text-sm font-semibold text-gray-900">
                        {customer.name}
                    </h4>
                    <div className="text-[13px] text-gray-400" title={snippet}>
                        {snippet.substring(0, 25)} ·&nbsp;
                        {dayjs(updated_time).from(dayjs(), true)}
                    </div>
                </div>
            </div>
        </div>
    );
}
