import { LIST_OF_CUSTOMERS } from '../actions/types';

const initialState = {
    loading: false,
    customer: {},
    messages: [],
};

export function listOfCustomersSelectedReducer(state = initialState, action) {
    const {type, payload} = action;

    switch (type) {
        case LIST_OF_CUSTOMERS.SET:
            return {
                ...state,
                ...payload,
            };
        case LIST_OF_CUSTOMERS.LOADING:
            return {
                ...state,
                loading: true,
            };
        case LIST_OF_CUSTOMERS.LOADED:
            return {
                ...state,
                loading: false,
            };
        case LIST_OF_CUSTOMERS.RESET:
            return initialState;
        default:
            return state;
    }
}
