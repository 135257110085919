import { sdk } from '@resources/helpers/facebookSDK';

export const facebookService = {
    getPageMessages(pageAccessToken, aditionalParams = {}) {
        return sdk.api({
            endpoint: `/me/conversations`,
            params: {
                access_token: pageAccessToken,
                fields: 'id,unread_count,updated_time,participants,snippet,can_reply',
                limit: 25,
                ...aditionalParams,
            },
        });
    },

    async sendMessage(data) {
        const { pageAccessToken, recipientId, imageUrl, message, facebookTag } =
            data;

        let commonMessageParams = {
            access_token: pageAccessToken,
            recipient: {
                id: recipientId,
            },
        };

        if (
            [
                'CONFIRMED_EVENT_UPDATE',
                'POST_PURCHASE_UPDATE',
                'ACCOUNT_UPDATE',
            ].includes(facebookTag)
        ) {
            commonMessageParams = {
                ...commonMessageParams,
                messaging_type: 'MESSAGE_TAG',
                tag: facebookTag,
            };
        }

        if (
            process.env.NODE_ENV == 'development' &&
            process.env.REACT_APP_ENABLE_BULK_IN_LOCAL == 'DISABLED'
        ) {
            console.warn(
                'message was not sent because is in development mode, please enable REACT_APP_ENABLE_BULK_IN_LOCAL to send messages'
            );

            return await new Promise((resolve) => setTimeout(resolve, 1000));
        }

        if (imageUrl) {
            const imageResponse = await sdk.api({
                endpoint: `/me/messages`,
                method: 'POST',
                params: {
                    ...commonMessageParams,
                    message: {
                        attachment: {
                            type: 'image',
                            payload: {
                                url: imageUrl,
                                is_reusable: true,
                            },
                        },
                    },
                },
            });

            if (imageResponse?.response?.error) {
                return Promise.resolve(imageResponse.response);
            }
        }

        return sdk.api({
            endpoint: `/me/messages`,
            method: 'POST',
            params: {
                ...commonMessageParams,
                message: { text: message },
            },
        });
    },

    getRecipientMessages(pageAccessToken, recipientId, aditionalParams = {}) {
        return sdk.api({
            endpoint: `/${recipientId}/messages`,
            params: {
                access_token: pageAccessToken,
                fields: 'id,created_time,from,to,message',
                limit: 25,
                ...aditionalParams,
            },
        });
    },
};
