import { CUSTOMER_SELECTED } from './types';

function getPayload(customerMessage) {
    const {
        id,
        updated_time,
        participants: {
            data: [customerInfo],
        },
    } = customerMessage;

    return {
        id,
        updated_time,
        customer: customerInfo,
    };
}

export const customerSelectedActions = {
    selectCustomer: (customer) => (dispatch) => {
        dispatch({
            type: CUSTOMER_SELECTED.SET,
            payload: getPayload(customer),
        });
    },

    selectFirstCustomer: () => (dispatch, getState) => {
        const {
            pageCustomers: { customers },
        } = getState();

        if (customers.length > 0) {
            dispatch({
                type: CUSTOMER_SELECTED.SET,
                payload: getPayload(customers[0]),
            });
        }
    },
};
