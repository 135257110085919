import React from 'react';
import { MessengerMenu } from './MessengerMenu';
import { MessengerContent } from './MessengerContent';

/**
 * Messenger
 * The Messenger component
 *
 * @returns {JSX.Element} A react component
 */
export function Messenger() {
    return (
        <div className="box box-inbox box--without-padding">
            <MessengerMenu />
            <MessengerContent />
        </div>
    );
}
