import axios from 'axios';

export const pageService = {
    getPage(data) {
        return axios.post('page', data).then((res) => res.data);
    },

    extendToken(accessToken) {
        return axios
            .post('page/extend-token', { access_token: accessToken })
            .then((res) => res.data);
    },
};
