import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { ReplyemLayout } from '@features/layouts';
import { Home } from '@pages/Home';
import { Page } from '@pages/Page';
import { Messenger } from '@pages/Page/Messenger';
import { Plans } from '@pages/Plans';
import { Affiliates } from '@pages/Affiliates';

/**
 * Router
 * The routes of the app
 *
 * @returns {JSX.Element} A react component
 */
export function PrivateRoutes() {
    return (
        <Routes>
            <Route path="/">
                <Route element={<ReplyemLayout />}>
                    <Route index element={<Home />} />

                    <Route path="/account">
                        <Route index element={<div>My page</div>} />

                        <Route
                            path="/account/billing"
                            element={<div>billing</div>}
                        />
                    </Route>

                    <Route path="/affiliates" element={<Affiliates />} />

                    <Route path="/:pageId" element={<Page />}>
                        <Route path="/:pageId" element={<Messenger />} />
                    </Route>
                </Route>

                <Route path="plans" element={<Plans />} />
            </Route>

            <Route path="*" element={<Navigate replace to="/" />} />
        </Routes>
    );
}
