import { AUTH } from '../actions/types';

const initialState = {
    isLoggedIn: false,
    fbUser: {},
    subscription: null,
    accessToken: null,
};

export function authReducer(state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case AUTH.SET_SUBSCRIPTION:
            return {
                ...state,
                subscription: {
                    ...state.subscription,
                    ...payload,
                },
            };
        case AUTH.LOGIN_SUCCESS:
            return {
                ...state,
                ...payload,
                isLoggedIn: true,
            };
        case AUTH.LOGIN_FAIL:
            return initialState;
        case AUTH.LOGOUT:
            return initialState;
        default:
            return state;
    }
}
