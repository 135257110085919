import React from 'react';
import { useFanPage } from '@resources/contexts';
import { BulkMessagesList } from './BulkMessagesList';
import { t } from 'i18next';

export function Sidebar() {
    const {
        page,
        bulkMessageStore: { bulkMessages },
    } = useFanPage();

    return (
        <div className="page-sidebar">
            <div className="page-image">
                <img alt={page.name} src={page?.picture?.data?.url} />
            </div>
            <div className="page-sidebar-content">
                <div className="page-info">
                    <h1 className="font-bold text-[#293748]">{page.name}</h1>
                    <p className="text-[#959CA7] text-[12px]">
                        {page.category}
                    </p>
                </div>
                <nav className="mb-4">
                    <button className="flex border-l-[2px] border-primary bg-slate-200 justify-start px-[10px] py-[8px] items-center w-full space-x-6 focus:outline-none text-gray-600">
                        <p className="text-base leading-4">{t('messages')}</p>
                    </button>
                </nav>

                {!!bulkMessages.length > 0 && (
                    <BulkMessagesList messages={bulkMessages} />
                )}
            </div>
        </div>
    );
}
