import React from 'react';
import { MessengerMenuHeader } from './MessengerMenuHeader';
import { MessengerMenuBody } from './MessengerMenuBody';

/**
 * MessengerMenu
 * The MessengerMenu component
 *
 * @returns {JSX.Element}
 */
export function MessengerMenu() {
    return (
        <aside className="page-inbox-left-side">
            <MessengerMenuHeader />
            <MessengerMenuBody />
        </aside>
    );
}
