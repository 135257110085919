import axios from 'axios';

export const fileService = {
    getUrlFile(image, access_type) {
        const formData = new FormData();
        formData.append('image', image);
        formData.append('access_type', access_type);

        return axios
            .post(
                `${process.env.REACT_APP_API_FILES_URL}/upload-images/store`,
                formData,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        Authorization: `Bearer nAZqxLDDYy4ogy3nV3Ul50ABSjlTQgqlh8UFM312`,
                    },
                }
            )
            .then((res) => res.data);
    },
};
