import { PAGE } from '../actions/types';

const initialState = {
    loading: false,
    page: {},
};

export function pageReducer(state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case PAGE.SET:
            return {
                ...state,
                ...payload,
            };
        case PAGE.SET_ACCESS_TOKEN:
            return {
                ...state,
                page: {
                    ...state.page,
                    access_token: payload,
                },
            };
        case PAGE.LOADING:
            return {
                ...state,
                loading: true,
            };
        case PAGE.LOADED:
            return {
                ...state,
                loading: false,
            };
        case PAGE.RESET:
            return initialState;
        default:
            return state;
    }
}
