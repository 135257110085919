import React, { useEffect, useRef, useState } from 'react';
import { t } from 'i18next';
import { useDispatch } from 'react-redux';
import { notifications } from '@mantine/notifications';
import { Alert } from '@mantine/core';
import { bulkMessageActions } from '@resources/actions';
import { useAuth, useFanPage, useGlobalModals } from '@resources/contexts';
import { ButtonSuscription } from '@features/components/ButtonSuscription';
import { PlansModal } from 'features/modals';
import { PackageModal } from 'features/modals/PackagesModal';
import { authActions } from 'resources/actions';
import { LoadingRecipients } from './LoadingRecipients';
import { BulkMessageProvider } from './BulkMessageContext';
import { RequireMoreMessages } from './RequireMoreMessages';
import { BulkMessageSteps } from './BulkMessageSteps';

export function BulkMessageWizard() {
    const fileRef = useRef();
    const messageRef = useRef();
    const [imageUrl, setImageUrl] = useState('');
    const dispatch = useDispatch();
    const [step, setStep] = useState(1);
    const [sending, setSending] = useState(false);
    const [customersSelected, setCustomersSelected] = useState([]);
    const [labelsSelected, setLabelsSelected] = useState([]);
    const [facebookTagSelected, setFacebookTagSelected] = useState('');
    const { showModal } = useGlobalModals();
    const {
        page,
        bulkMessageStore: { loading, customers, customersTotal },
    } = useFanPage();
    const { subscription: userSubscription } = useAuth();

    useEffect(() => {
        getRecipients();

        const interval = setInterval(() => {
            getRecipients();
        }, 5000);

        return () => clearInterval(interval);
    }, []);

    useEffect(() => {
        if (
            !customersTotal &&
            (userSubscription.available_messages > 0 ||
                userSubscription.is_unlimited)
        ) {
            dispatch(bulkMessageActions.getAllCustomers());
        }
    }, [userSubscription.available_messages]);

    function getRecipients() {
        return dispatch(authActions.updateAvailableMessages());
    }

    function checkForm() {
        if (!messageRef.current.value) {
            notifications.show({
                color: 'yellow',
                title: t('incomplete_form'),
                message: t('message_required'),
            });
            return false;
        }

        if (
            !userSubscription.is_unlimited &&
            userSubscription.available_messages == 0
        ) {
            notifications.show({
                color: 'yellow',
                title: t('you_have_no_messages'),
                autoClose: false,
                message: (
                    <>
                        {t('please_purchase_a_subscription')}{' '}
                        <div className="mt-3">
                            {userSubscription.access_type == 'trial' ? (
                                <button
                                    className="btn btn-primary"
                                    onClick={handleShowPlansModal}
                                >
                                    {t('buy_a_subscription')}
                                </button>
                            ) : (
                                <button
                                    className="btn btn-primary"
                                    onClick={handleShowPackagesModal}
                                >
                                    {t('buy_a_addon')}
                                </button>
                            )}
                        </div>
                    </>
                ),
            });
            return false;
        }

        return true;
    }

    function sendBulkMessage() {
        if (!checkForm() || sending) return;

        setSending(true);

        let customers = customersSelected;

        if (
            !userSubscription.is_unlimited &&
            userSubscription.available_messages < customersSelected.length
        ) {
            customers = customersSelected.slice(
                0,
                userSubscription.available_messages
            );
        }

        dispatch(
            bulkMessageActions.stackNewMessage({
                page,
                customers,
                facebookTag: facebookTagSelected,
                labels: labelsSelected,
                image: imageUrl,
                message: messageRef.current.value,
            })
        )
            .then(() => {
                notifications.show({
                    color: 'green',
                    title: t('sending_message'),
                    message: t('sending_messages_description', {
                        people: customersSelected.length,
                    }),
                });

                resetForm();
            })
            .finally(() => setSending(false));
    }

    function resetForm() {
        setStep(1);
        setCustomersSelected([]);
        setLabelsSelected([]);
        setFacebookTagSelected('');
        setImageUrl('');
        messageRef.current.value = '';
    }

    function handleShowPlansModal() {
        showModal(<PlansModal />);
    }

    function handleShowPackagesModal() {
        showModal(<PackageModal />);
    }

    const requireMoreMessages =
        !userSubscription.is_unlimited && !userSubscription.available_messages;

    return (
        <div className="bulk-message">
            <div className="bulk-title">
                <h1>{t('send_bulk_message').toUpperCase()}</h1>
            </div>
            <div className="bulk-content">
                <BulkMessageProvider
                    values={{
                        // States
                        step,
                        userSubscription,
                        labelsSelected,
                        customersSelected,
                        facebookTagSelected,
                        fileRef,
                        messageRef,
                        imageUrl,

                        // Functions
                        setStep,
                        setLabelsSelected,
                        setCustomersSelected,
                        setFacebookTagSelected,
                        sendBulkMessage,
                        setImageUrl,
                    }}
                >
                    {requireMoreMessages ? (
                        <RequireMoreMessages />
                    ) : loading ? (
                        <LoadingRecipients customersTotal={customersTotal} />
                    ) : (
                        <div className="steps">
                            {!userSubscription.is_unlimited && (
                                <Alert
                                    className="mb-4"
                                    color={
                                        userSubscription.available_messages == 0
                                            ? 'red'
                                            : 'blue'
                                    }
                                >
                                    {t('available_messages', {
                                        people: userSubscription.available_messages,
                                    })}{' '}
                                    {t('need_more_messages')}{' '}
                                    <ButtonSuscription />
                                </Alert>
                            )}

                            <BulkMessageSteps />
                        </div>
                    )}
                </BulkMessageProvider>
            </div>
        </div>
    );
}
