import Cookies from 'js-cookie';
import { sdk } from '@resources/helpers/facebookSDK';
import { userService } from '@resources/services';
import { APP } from '@resources/constants';
import { AUTH } from './types';

export const authActions = {
    login: () => (dispatch) => {
        return sdk
            .login()
            .then((res) => {
                userService
                    .login(res.access_token)
                    .then(({ data: { token, user } }) => {
                        Cookies.set(APP.COOKIE, token, {
                            expires: 10 * 365, // days
                        });

                        dispatch({
                            type: AUTH.LOGIN_SUCCESS,
                            payload: {
                                fbUser: res.user,
                                subscription: user,
                                accessToken: res.accessToken,
                            },
                        });
                    });
            })
            .catch(() => dispatch({ type: AUTH.LOGIN_FAIL }));
    },

    updateAvailableMessages: () => (dispatch) => {
        return userService
            .getAvailableMessages()
            .then(({ available_messages }) => {
                dispatch({
                    type: AUTH.SET_SUBSCRIPTION,
                    payload: {
                        available_messages,
                    },
                });

                return available_messages;
            });
    },

    checkAuthState: () => (dispatch) => {
        const accessToken = Cookies.get(APP.COOKIE);

        if (!accessToken) {
            return Promise.resolve(dispatch({ type: AUTH.LOGIN_FAIL }));
        }

        return sdk
            .checkAuthState()
            .then((res) => {
                return userService.getUser().then((user) => {
                    dispatch({
                        type: AUTH.LOGIN_SUCCESS,
                        payload: {
                            fbUser: res.user,
                            subscription: user,
                            accessToken: res.access_token,
                        },
                    });
                });
            })
            .catch(() => dispatch({ type: AUTH.LOGIN_FAIL }));
    },

    logout: () => (dispatch) => {
        return sdk.logout().then(() => {
            dispatch({
                type: AUTH.LOGOUT,
            });
        });
    },
};
