import { useCallback, useEffect, useRef } from 'react';

export function useScroll({
    onScrollEnd = () => {},
    onScrollStart = () => {},
}) {
    const ref = useRef();

    function onScroll() {
        if (ref.current.scrollTop <= 1) {
            onScrollStart();
        }

        if (
            ref.current.scrollTop + ref.current.clientHeight + 1 >=
            ref.current.scrollHeight
        ) {
            onScrollEnd();
        }
    }

    function scrollToBottom() {
        if (ref.current) {
            ref.current.scrollTop = ref.current.scrollHeight;
        }
    }

    function goToScroll(newScrollTop = 0) {
        if (ref.current) {
            ref.current.scrollTop = newScrollTop;
        }
    }

    useEffect(() => {
        if (ref.current && typeof onScrollEnd === 'function') {
            ref.current.addEventListener('scroll', onScroll);
        }

        return () => {
            if (ref.current) {
                ref.current.removeEventListener('scroll', onScroll);
            }
        };
    }, [ref.current, onScrollEnd]);

    return {
        // Refs
        scrollRefElement: ref,

        // Methods
        scrollToBottom,
        goToScroll,
    };
}
