import React from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { t } from 'i18next';
import { useAuth, useGlobalModals } from '@resources/contexts';
import { UserAvatar } from '@features/components';
import { UserModal } from 'features/modals/UserModal';
import { FaSignOutAlt } from "react-icons/fa";

export function Sidebar() {
    const { fbUser, subscription, logout } = useAuth();
    const { showModal } = useGlobalModals();

    function handleLogout() {
        logout();
    }

    function handleShowUserModal() {
        showModal(<UserModal />);
    }

    const accounts = fbUser?.accounts?.data || [];

    return (
        <div className="flex flex-col items-center h-full overflow-hidden text-white bg-primary drop-shadow-md fixed">
            <Link
                to="/"
                className="flex items-center w-full bg-white justify-center py-4"
            >
                <img src="/assets/imgs/logo.png" />
            </Link>

            <div className="flex flex-col items-center overflow-y-auto border-t border-gray-400">
                {accounts
                    .filter((a) => a.access_token)
                    .map((page) => (
                        <Link
                            to={`/${page.id}`}
                            key={page.id}
                            className="flex items-center justify-center mx-2 w-8 h-8 mt-5 rounded hover:bg-indigo-700"
                            data-tooltip-target="tooltip-right"
                            data-tooltip-placement="right"
                        >
                            <img
                                className="rounded"
                                src={page.picture.data.url}
                            />
                        </Link>
                    ))}
            </div>

            <Link
                to={/*"/account"*/ '#'}
                onClick={handleShowUserModal}
                className="relative flex items-center justify-center w-full mt-auto py-4 bg-primary2"
            >
                <UserAvatar className="rounded-full w-8 h-8" />
                {!subscription.is_unlimited && (
                    <div
                        title={t('available_messages', {
                            people: subscription.available_messages,
                        })}
                        className={classNames({
                            badge: true,
                            'badge--small': true,
                            'badge-indicator': true,
                            'badge-primary':
                                subscription.available_messages > 1000,
                            'badge-warning':
                                subscription.available_messages <= 1000,
                            'badge-danger':
                                subscription.available_messages < 100,
                        })}
                    >
                        {subscription.available_messages}
                    </div>
                )}
            </Link>
            <Link to={'#'} onClick={handleLogout} className="flex items-center justify-center w-full py-4 bg-primary2">
                <FaSignOutAlt title={t('logout')} className="w-6 h-6" />
            </Link>
        </div>
    );
}
