import React from 'react';
import { Modal } from '@mantine/core';
import { subscriptionService } from '@resources/services';

import './PackagesModal.scss';

export function PackageModal({ id, hideModal }) {
    function handleSelectPackage(priceId) {
        subscriptionService
            .getStripeCheckoutLink({
                price_id: priceId,
                is_addon: true,
            })
            .then((res) => {
                window.open(res.data.url, '_blank');
            });
    }

    return (
        <>
            <Modal.Root opened={true} onClose={hideModal} size="1200px">
                <Modal.Overlay color="gray" opacity="0.55" blur="3" />
                <Modal.Content>
                    <div className="px-11 py-6">
                        <header className="pt-6 pb-4 text-center">
                            <h1 className="text-[30px] text-black font-bold mb-0">
                                Addons
                            </h1>
                            <p className="text-gray-500 text-sm mt-0 max-w-[700px] mx-auto">
                                You can purchase one of the following Addons
                                while you wait for your plan messages to be
                                reset.
                            </p>
                            <p className="text-gray-400 text-sm mt-0 max-w-[700px] mx-auto">
                                <strong className="text-black">
                                    These messages do not expire
                                </strong>
                                , when you have active subscription messages
                                your subscription messages will be used first,
                                then the messages contracted in a package. *An
                                active subscription is required to use these
                                messages.
                            </p>
                        </header>
                        <div className="packages">
                            <div className="package">
                                <h2 className="package-title">Addon 1</h2>
                                <p className="mt-2 text-xs leading-normal">
                                    Include <strong>1 000</strong> messages
                                </p>
                                <p className="package-description">
                                    These messages do not expire.
                                </p>
                                <p className="mt-4 text-center">
                                    <span className="text-4xl font-extrabold">
                                        $7.98
                                    </span>
                                </p>
                                <div className="mt-4">
                                    <button
                                        type="button"
                                        className="block btn btn-primary btn-block"
                                        onClick={() =>
                                            handleSelectPackage('package_01')
                                        }
                                    >
                                        Buy Addon
                                    </button>
                                </div>
                            </div>

                            <div className="package">
                                <h2 className="package-title">Addon 2</h2>
                                <p className="mt-2 text-xs leading-normal">
                                    Include <strong>2 000</strong> messages
                                </p>
                                <p className="package-description">
                                    These messages do not expire.
                                </p>
                                <p className="mt-4 text-center">
                                    <span className="text-4xl font-extrabold">
                                        $14.98
                                    </span>
                                </p>
                                <div className="mt-4">
                                    <button
                                        type="button"
                                        className="block btn btn-primary btn-block"
                                        onClick={() =>
                                            handleSelectPackage('package_02')
                                        }
                                    >
                                        Buy Addon
                                    </button>
                                </div>
                            </div>

                            <div className="package">
                                <h2 className="package-title">Addon 3</h2>
                                <p className="mt-2 text-xs leading-normal">
                                    Include <strong>10 000</strong> messages
                                </p>
                                <p className="package-description">
                                    These messages do not expire.
                                </p>
                                <p className="mt-4 text-center">
                                    <span className="text-4xl font-extrabold">
                                        $29.98
                                    </span>
                                </p>
                                <div className="mt-4">
                                    <button
                                        type="button"
                                        className="block btn btn-primary btn-block"
                                        onClick={() =>
                                            handleSelectPackage('package_03')
                                        }
                                    >
                                        Buy Addon
                                    </button>
                                </div>
                            </div>

                            <div className="package">
                                <h2 className="package-title">Addon 4</h2>
                                <p className="mt-2 text-xs leading-normal">
                                    Include <strong>50 000</strong> messages
                                </p>
                                <p className="package-description">
                                    These messages do not expire.
                                </p>
                                <p className="mt-4 text-center">
                                    <span className="text-4xl font-extrabold">
                                        $49.98
                                    </span>
                                </p>
                                <div className="mt-4">
                                    <button
                                        type="button"
                                        className="block btn btn-primary btn-block"
                                        onClick={() =>
                                            handleSelectPackage('package_04')
                                        }
                                    >
                                        Buy Addon
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Content>
            </Modal.Root>
        </>
    );
}
