// Bengali bn
export default {
    translations: {
        search: 'সার্চ করুন',
        social_inbox_marketing_is_born: 'সোশ্যাল ইনবক্স মার্কেটিং জন্ম নেয়',
        login_with_facebook: 'ফেসবুকে লগইন করুন',
        logout: 'লগ আউট',
        login_description: `
            <strong>প্রেরিত</strong> ইনবক্স মেসেজগুলি
            <strong>সহ হেজার লোকের</strong> কাছে
            যাদের ইতিমধ্যে আপনার পেইজে কোনো আলাপ শুরু করেছেন।
            <br />
            <br />
            আপনি চাইলে আপনার যে কোন লেবেল নির্বাচন করে প্রচার, সংবাদ, অথবা
            যে কোন কিছু শেয়ার করতে পারেন।
        `,
        search_page: 'পেজ সার্চ করুন...',
        people_like_this: '{{ লোক }} এটি পছন্দ করেন',
        view_page: 'পেজ দেখুন',
        add_a_new_page: 'নতুন পেজ যোগ করুন',
        support: 'সাহায্য করা আবশ্যক?',
        earn_money_with_us: 'আমাদের সাথে টাকা আয় করুন',
        learn_more: 'আরও জানুন',
        available_messages: 'আপনি এখানে {{ লোক }} মেসেজ পাচ্ছেন।',
        need_more_messages: 'আপনার আরো মেসেজ দরকার?',
        messages: 'মেসেজগুলি',
        search_and_filter_conversations: 'অনুসঙ্গ খোঁজ করুন এবং পরিবার করুন',
        loading: 'লোড হচ্ছে',
        loading_chat_messages: 'চ্যাট মেসেজগুলি লোড হচ্ছে',
        loading_all_recipients: 'সমস্ত প্রাপকগণ লোড হচ্ছে',
        send_message: 'মেসেজ প্রেরণ করুন',
        send_bulk_message: 'নতুন বাল্ক মেসেজ প্রেরণ করুন',
        no_such_more_messages: ' কোনো আরো মেসেজ নেই',
        write_your_message: 'আপনার মেসেজ লিখুন',
        upload_image: 'ছবি আপলোড করুন',
        incomplete_form: 'অসম্পূর্ণ ফরম',
        message_required: 'দয়া করে একটি মেসেজ লিখুন',
        you_have_no_messages: 'আপনার কোনো মেসেজ নেই',
        please_purchase_a_subscription:
            'মেসেজ প্রেরণের জন্য একটি সাবস্ক্রিপশন কিনুন',
        buy_a_subscription: 'সাবস্ক্রিপশন কিনুন',
        buy_a_addon: 'অ্যাড-অন কিনুন',
        sending_message: 'মেসেজ প্রেরণ করা হচ্ছে',
        sending_messages_description:
            '{{ লোক }} প্রাপকগণের দিকে মেসেজ প্রেরণ করা হচ্ছে।',
        step: 'পদক্ষেপ {{ পদক্ষেপ }}',
        select_audience: 'আপনার পাঠকগণ নির্বাচন করুন',
        select_fb_tag: 'আপনার মেসেজের ধরন নির্বাচন করুন',
        select_audience_description1:
            'মেসেজ কাকে প্রেরণ করতে, তা নির্বাচন করুন',
        select_audience_description2:
            'পেইজ ইনবক্সে সমস্ত প্রাপকগণ নির্বাচন করতে "সমস্ত প্রাপকগণ" ব্যবহার করুন',
        select_audience_description3:
            'আপনি লোকগণ বাদ দেওয়ার জন্য লেবেল ব্যবহার করতে পারেন',
        labels: 'লেবেলস',
        recipients: 'প্রাপকগণ',
        all_recipients: 'সমস্ত প্রাপকগণ',
        no_label: 'কোনো লেবেল নেই',
        next_step: 'পরবর্তী পদক্ষেপ',
        select_fb_tag_description:
            'আপনার পেইজ ইনবক্সে সম্প্রতি ২৪ ঘণ্টা বাড়ি হয়নি সে লোকদের জন্য',
        tag_confirmed_event_update: 'নিশ্চিত ইভেন্ট আপডেট',
        tag_confirmed_event_update_description: `
            সেই লোকগণের জন্য যারা একটি ইভেন্টে নিশ্চিত হয়েছেন।
        `,
        tag_post_purchase_update: 'পোস্ট-পার্চেস আপডেট',
        tag_post_purchase_update_description: `
            সেই লোকগণের জন্য যারা আপনার অ্যাপ্লিকেশনে একটি ক্রয় করেছেন।
        `,
        tag_account_update: 'অ্যাকাউন্ট আপডেট',
        tag_account_update_description: `
            সেই লোকগণের জন্য যারা আপনার এ্যাপ্লিকেশনে একটি অ্যাকাউন্ট নিবন্ধিত রাখেন।
        `,
        select_fb_tag_footer_description: `
            আপনি এই ধরনের কন্টেন্ট নির্বাচন করুন যাতে
            সেই সম্পর্কিত মেসেজ প্রেরণ করতে পারেন। এই কোনো ধরনের কন্টেন্ট প্রেরণ করলে
            আপনার Facebook™ পেইজ স্থগিত হতে পারে।
            <strong>
                উপরের কন্টেন্ট টি ব্যবহার করে প্রমোশন প্রেরণ করতে "আপনার নিজের জোখ" এ ডেন্জার এর সাথে প্রেরণ করুন।
            </strong>
        `,
        more_info: 'আরও জানুন',
        write_your_message_and_send: 'আপনার মেসেজ লিখুন এবং প্রেরণ করুন',
        this_message_will_be_sent_with_a_watermark:
            'এই মেসেজটি একটি রিপ্লাইএম ওয়াটারমার্ক সহ প্রেরিত হবে, আপনি যদি আমাদের ওয়াটারমার্ক ছাড়া আপনার মেসেজ প্রেরণ করতে চান তবে আপনি একটি সাবস্ক্রিপশন কিনতে পারেন।',
        this_messsage_will_be_sent_only_to:
            'এই মেসেজ শুধুমাত্র {{ লোক }} প্রাপকগণে প্রেরিত হবে।',
        messages_rejected:
            '{{ মেসেজ_প্রতিস্থাপন_করা_হয়নি }} মেসেজ প্রতিস্থাপন করা হয়নি',
        messages_sent_successfully: `
            <strong>{{ মেসেজ_সফলভাবে_প্রেরণ_করা_হয়েছে }}</strong> মেসেজ প্রেরণ করা হয়েছে,
            <strong>{{ ত্রুটি_মেসেজগুলি }}</strong> প্রতিস্থাপন করা হয়নি।
        `,
        messages_in_progress: `মেসেজ প্রেরণের মধ্যে <strong>{{ মেসেজ_প্রেরিত }}</strong>/<strong>{{ মোট_মেসেজ }}</strong> মেসেজ প্রেরণ করা হচ্ছে।`,
        affiliates: {
            title: 'অ্যাফিলিয়েটগণ',
            subtitle: 'আমাদের সাথে টাকা উপার্জন করুন',
            how_it_works: 'এটি কীভাবে কাজ করে?',
            how_it_works_description: `
                আপনি টাকা আয় করতে আপনার বন্ধু, পরিবার বা যে কোন কাউকে আমন্ত্রণ জানাতে পারেন,
                <strong class="text-primary">
                    আপনি 2 মাস বয়ে আপনার প্রেরণকারীর সমস্ত কেনাকাটা থেকে 20% উপার্জন করবেন
                </strong>
                , আপনার আমন্ত্রণকারীরা 3 মাসের জন্য যে কোন প্ল্যানে 15% ছাড় পাবেন।
            `,
            sign_up: 'সাইন আপ করুন',
            sign_up_description: `
                টাকা উপার্জন করতে শুরু করতে আপনাকে আমাদের অ্যাফিলিয়েট প্রোগ্রামে নিবন্ধন করতে হবে।
            `,
            invites: 'আমন্ত্রণ জানান',
            invites_description: `
                সমস্ত আপনার বন্ধু, পরিবার বা অন্য কেউকে আপনার রেফারেন্স প্রোগ্রাম দ্বারা
                রেপ্লাইম ব্যবহার করার জন্য আমন্ত্রিত করুন
                প্রোগ্রাম।
            `,
            earn_money: 'টাকা আয় করুন',
            earn_money_description:
                'আমাদের সাথে টাকা উপার্জন করতে শুরু করুন এবং আসান টাকা উপার্জন করতে শুরু করুন',
            start_earning_money_now: 'এখনই টাকা উপার্জন করতে শুরু করুন',
            terms: 'শর্তাবলি',
            terms_description: `
                আপনি আপনার প্রথম পেমেন্টে আবেদন করতে পারেন যতটুকু আপনার ন্যূনতম শূন্য মূল্যে ব্যালেন্স রাখা উচিত। পেমেন্টগুলি পেপ্যালের মাধ্যমে প্রস্তুত করা হবে। উপরে, আপনি আপনার ব্যবহারকারীদের প্রথম 60 দিনের মধ্যে তাদের সমস্ত কেনাকাটা উপর 20% কমিশন উপার্জন করার সুযোগ পাবেন। আপনি আপনার আয়ের সমস্ত হিসাব নিম্নোক্ত লিঙ্কে ট্র্যাক করতে পারেন
            `,
        },
        popular: 'জনপ্রিয়',
        massive_messages: 'বৃহত্তর বার্তা',
        massive_replies: 'বৃহত্তর উত্তর',
        pages: 'পৃষ্ঠা',
        labeling: 'লেবেলিং',
        choose_plan: 'প্ল্যান চয়ন করুন',
        choose_your_plan: 'আপনার প্ল্যান চয়ন করুন',
        rederal_discount: 'রেফারাল ডিসকাউন্ট',
        massive: 'বৃহত্তর',
        month: 'মাস',
        the_number_message: 'বার্তা সংখ্যা মাসিক',
        plans: 'প্ল্যানস',
        union_y: 'এবং',
        plans_page: {
            title: 'আপনার পরিকল্পনা মেয়াদ শেষ হয়েছে।',
            description: `চিন্তা করবেন না, আপনি একটি পরিকল্পনা চয়ন করতে পারেন এবং
                যে সুবিধা রিপ্লাই'এম দেয়, তা থাকতে পারবে।`,
            mistake: 'আপনার একটি কার্যক্ষম পরিকল্পনা আছে যদি',
            link_mistake: 'যোগাযোগ করুন',
            referral_discount:
                'রেফারেল ডিসকাউন্টটি পরবর্তী পৃষ্ঠায় দেখানো হয়।',
            starter_header: 'আরম্ভণীয়',
            pequeno_negocio: 'ছোট ব্যবসায়',
            marketer_bussines: 'মার্কেটার',
            ilimitado: 'অসীম',
            starter_price_annually:
                'বা বর্ষান্তে {{ priceStarterAnnualy }} প্রদান করার সময়',
            pequeno_negocio_price_annually:
                'বা বর্ষান্তে {{ priceNegocioAnnualy }} প্রদান করার সময়',
            marketer_price_annually:
                'বা বর্ষান্তে {{priceMarketerAnnually}} প্রদান করার সময়',
            unlimited_price_annually:
                'বা বর্ষান্তে {{priceUnlimitedAnnually}} প্রদান করার সময়',
            months: '/মাস',
            unlimited: 'অসীম',
            suscribe: 'সাবস্ক্রাইব করুন',
        },
    },
};
