import React, { useState } from 'react';
import classNames from 'classnames';
import { notifications } from '@mantine/notifications';
import { Alert } from '@mantine/core';
import { PlansModal } from '@features/modals';
import { ButtonSuscription } from '@features/components/ButtonSuscription';
import { useBulkMessage } from '../../BulkMessageContext';
import { fileService } from '@resources/services';
import { useGlobalModals } from '@resources/contexts';
import { t } from 'i18next';

export function Step3() {
    const [showAdvanced, setShowAdvanced] = useState(false);
    const { showModal } = useGlobalModals();
    const {
        step,
        customersSelected,
        userSubscription,
        setStep,
        facebookTagSelected,
        sendBulkMessage,
        fileRef,
        messageRef,
        imageUrl,
        setImageUrl,
    } = useBulkMessage();

    function handleFileChange(event) {
        if (!event.target.files.length) return;

        if (userSubscription.access_type === 'trial') {
            notifications.show({
                autoClose: false,
                message: (
                    <>
                        {t('this_message_will_be_sent_with_a_watermark')}
                        <div className="mt-3">
                            <button
                                className="btn btn-primary"
                                onClick={handleShowPlansModal}
                            >
                                {t('buy_a_subscription')}
                            </button>
                        </div>
                    </>
                ),
            });
        }

        fileService
            .getUrlFile(event.target.files[0], userSubscription.access_type)
            .then((res) => setImageUrl(res.url));
    }

    function handleShowPlansModal() {
        showModal(<PlansModal />);
    }

    function handleSendBulkMessage() {
        //sendBulkMessage();
    }

    const step3IsAvailable =
        customersSelected.length > 0 && facebookTagSelected.length > 0;

    return (
        <div>
            <div
                className="font-bold cursor-pointer"
                onClick={() => step3IsAvailable && setStep(3)}
            >
                {t('step', { step: 3 })}: {t('write_your_message_and_send')}
                <div
                    className={classNames({
                        arrow: true,
                        'arrow-up': step !== 3,
                        'arrow-down': step === 3,
                    })}
                ></div>
            </div>
            {step === 3 && (
                <div className="p-3">
                    {!userSubscription.is_unlimited && (
                        <>
                            {userSubscription.available_messages == 0 && (
                                <Alert
                                    icon={<i className="fa fa-warning"></i>}
                                    color="yellow"
                                >
                                    {t('available_messages', {
                                        people: userSubscription.available_messages,
                                    })}
                                    <div className="flex mt-3">
                                        <ButtonSuscription />
                                    </div>
                                </Alert>
                            )}
                            {userSubscription.available_messages <
                                customersSelected.length && (
                                <Alert
                                    icon={<i className="fa fa-warning"></i>}
                                    color="yellow"
                                >
                                    {t('this_messsage_will_be_sent_only_to', {
                                        people: userSubscription.available_messages,
                                    })}
                                    <div className="flex mt-3">
                                        <ButtonSuscription />
                                    </div>
                                </Alert>
                            )}
                        </>
                    )}

                    <div className="pl-3">
                        {/*<div className="pl-2">
                            <div
                                className=" text-gray-600 text-[14px] cursor-pointer"
                                onClick={() =>
                                    setShowAdvanced((value) => !value)
                                }
                            >
                                Advanced{' '}
                                <div
                                    className={classNames({
                                        arrow: true,
                                        'arrow-up': !showAdvanced,
                                        'arrow-down': showAdvanced,
                                    })}
                                ></div>
                            </div>
                            {showAdvanced && (
                                <div className="pl-2">
                                    <input />
                                </div>
                            )}
                        </div>*/}
                        <label htmlFor="chat" className="sr-only">
                            {t('write_your_message')}!
                        </label>
                        <div className="flex items-center px-3 py-2 rounded-lg bg-gray-50">
                            <textarea
                                ref={messageRef}
                                rows="4"
                                className="block mx-4 p-2.5 w-full min-h-[41px] text-sm text-gray-900 bg-white rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500"
                                placeholder={`${t('write_your_message')}!`}
                            ></textarea>

                            {imageUrl ? (
                                <div className="relative">
                                    <img
                                        className="rounded-full items-start flex-shrink-0"
                                        width="32"
                                        height="32"
                                        src={imageUrl}
                                    />
                                    <div
                                        className="absolute top-[-5px] right-[-5px] bg-gray-300 rounded-full text-gray-600 cursor-pointer px-1 py-0 text-[12px]"
                                        onClick={() => setImageUrl('')}
                                    >
                                        x
                                    </div>
                                </div>
                            ) : (
                                <label
                                    htmlFor="file-upload"
                                    className="inline-flex justify-center p-2 text-gray-500 rounded-lg cursor-pointer hover:text-gray-900 hover:bg-gray-100"
                                >
                                    <input
                                        type="file"
                                        className="hidden"
                                        ref={fileRef}
                                        onChange={handleFileChange}
                                        id="file-upload"
                                        name="file-upload"
                                        accept="image/*"
                                    />

                                    <svg
                                        aria-hidden="true"
                                        className="w-6 h-6"
                                        fill="currentColor"
                                        viewBox="0 0 20 20"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            fillRule="evenodd"
                                            d="M4 3a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V5a2 2 0 00-2-2H4zm12 12H4l4-8 3 6 2-4 3 6z"
                                            clipRule="evenodd"
                                        ></path>
                                    </svg>
                                    <span className="sr-only">
                                        {t('upload_image')}
                                    </span>
                                </label>
                            )}

                            <button
                                type="button"
                                className="inline-flex justify-center p-2 text-primary rounded-full cursor-pointer hover:bg-blue-100"
                                onClick={sendBulkMessage}
                            >
                                <svg
                                    aria-hidden="true"
                                    className="w-6 h-6 rotate-90"
                                    fill="currentColor"
                                    viewBox="0 0 20 20"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path d="M10.894 2.553a1 1 0 00-1.788 0l-7 14a1 1 0 001.169 1.409l5-1.429A1 1 0 009 15.571V11a1 1 0 112 0v4.571a1 1 0 00.725.962l5 1.428a1 1 0 001.17-1.408l-7-14z"></path>
                                </svg>
                                <span className="sr-only">
                                    {t('send_message')}
                                </span>
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}
