import React from 'react';
import { useAuth, useGlobalModals } from '@resources/contexts';
import { PlansModal } from '@features/modals';
import { PackageModal } from '@features/modals/PackagesModal';
import { t } from 'i18next';

export function ButtonSuscription() {
    const { showModal } = useGlobalModals();
    const { subscription: userSubscription } = useAuth();

    const userHasTrial = userSubscription.access_type == 'trial';

    function handleShowModal() {
        if (userHasTrial) {
            showModal(<PlansModal />);
        } else {
            showModal(<PackageModal />);
        }
    }

    return (
        <button className="btn btn-primary" onClick={handleShowModal}>
            {userHasTrial ? t('buy_a_subscription') : t('buy_a_addon')}
        </button>
    );
}
