/* eslint-disable no-constant-condition */
import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { notifications } from '@mantine/notifications';
import { useAuth, FanPageProvider, useGlobalModals } from '@resources/contexts';
import { pageActions, customerSelectedActions } from '@resources/actions';
import {
    PAGE,
    BULK_MESSAGE,
    PAGE_CUSTOMERS,
    CUSTOMER_SELECTED,
    LIST_OF_CUSTOMERS,
} from '@resources/actions/types';
import { PlansModal } from '@features/modals';
import { FanPageLayout } from './FanPageLayout';

import './page.scss';
import { t } from 'i18next';

/**
 * Page
 * The Page component
 *
 * @returns {JSX.Element} A react component
 */
export function Page() {
    const { fbUser, subscription } = useAuth();
    const { pageId } = useParams();
    const {
        page: pageStore,
        pageCustomers: customersStore,
        customerSelected: customerSelectedStore,
        bulkMessage: bulkMessageStore,
    } = useSelector((state) => state);
    const { showModal } = useGlobalModals();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [showBulkMessageWizard, setShowBulkMessageWizard] = useState(false);

    useEffect(() => {
        const page = (fbUser?.accounts?.data || []).find(
            (page) => page.id === pageId
        );

        if (!page) {
            navigate('/');
        }

        dispatch(pageActions.getPage(page))
            .then((pageData) => {
                if (
                    subscription.access_type === 'trial' &&
                    !pageData.has_trial
                ) {
                    notifications.show({
                        autoClose: false,
                        color: 'yellow',
                        message: (
                            <>
                                This page has already been used for a trial, in
                                order to use it you have to subscribe.
                                <div className="mt-2">
                                    <button
                                        className="btn btn-primary"
                                        onClick={handleShowPlansModal}
                                    >
                                        {t('buy_a_subscription')}
                                    </button>
                                </div>
                            </>
                        ),
                    });

                    navigate('/');
                }

                if (pageData.banned) {
                    notifications.show({
                        autoClose: false,
                        color: 'yellow',
                        message: 'Your page has been banned',
                    });

                    navigate('/');
                }

                dispatch(pageActions.extendToken(page.access_token)).then(
                    () => {
                        dispatch(pageActions.getPageCustomers()).then(() => {
                            dispatch(
                                customerSelectedActions.selectFirstCustomer()
                            );
                        });
                    }
                );
            })
            .catch((res) => navigate('/'));

        return () => {
            setShowBulkMessageWizard(false);

            dispatch({ type: PAGE.RESET });
            dispatch({ type: PAGE_CUSTOMERS.RESET });
            dispatch({ type: CUSTOMER_SELECTED.RESET });
            dispatch({ type: LIST_OF_CUSTOMERS.RESET });
            dispatch({ type: BULK_MESSAGE.RESET });
        };
    }, [pageId]);

    function showBulkMessage() {
        setShowBulkMessageWizard(true);
    }

    function hideBulkMessage() {
        setShowBulkMessageWizard(false);

        dispatch(customerSelectedActions.selectFirstCustomer());
    }

    function toggleBulkMessageWizard() {
        if (showBulkMessageWizard) {
            hideBulkMessage();
        } else {
            showBulkMessage();
        }
    }

    function handleShowPlansModal() {
        showModal(<PlansModal/>);
    }

    return (
        <FanPageProvider
            fanPageId={pageId}
            {...{
                ...pageStore,

                // States
                customersStore,
                customerSelectedStore,
                bulkMessageStore,
                showBulkMessageWizard,

                // Methods
                setShowBulkMessageWizard,
                showBulkMessage,
                hideBulkMessage,
                toggleBulkMessageWizard,
            }}
        >
            <FanPageLayout/>
        </FanPageProvider>
    );
}
