import React, { useEffect } from 'react';
import classNames from 'classnames';
import { useBulkMessage } from '../../BulkMessageContext';
import { useFanPage } from '@resources/contexts';
import { Button } from '@mantine/core';
import { t } from 'i18next';

export function Step1() {
    const {
        step,
        setStep,
        customersSelected,
        labelsSelected,
        setLabelsSelected,
        setCustomersSelected,
    } = useBulkMessage();

    const {
        bulkMessageStore: { customers, customersTotal },
    } = useFanPage();

    useEffect(() => {
        setLabelsSelected('all_labels');
        filterCustomers('all_labels');
    }, []);

    function handleToggleLabel(label) {
        let newLabelsSelected = [];

        if (labelsSelected.includes(label)) {
            newLabelsSelected = labelsSelected.filter((l) => l !== label);
        } else {
            newLabelsSelected = [...labelsSelected, label];
        }

        setLabelsSelected(newLabelsSelected || []);
        filterCustomers(newLabelsSelected || []);
    }

    function filterCustomers(newLabelsSelected) {
        let newCustomersSelected = [];

        if (newLabelsSelected.includes('all_labels')) {
            newCustomersSelected = customers;
        } else if (newLabelsSelected.includes('no_labels')) {
            newCustomersSelected = customers;
        }

        setCustomersSelected(newCustomersSelected);
    }

    const stepAvailable = customersSelected.length > 0;

    return (
        <div>
            <div
                className="font-bold cursor-pointer"
                onClick={() => setStep(1)}
            >
                {t('step', { step: 1 })}: {t('select_audience')}
                <div
                    className={classNames({
                        arrow: true,
                        'arrow-up': step !== 1,
                        'arrow-down': step === 1,
                    })}
                ></div>
            </div>
            {step === 1 && (
                <div className="p-3">
                    <p className="text-gray-500 text-[14px] mb-2">
                        •{t('select_audience_description1')}
                        <br />•{t('select_audience_description2')}
                        <br />•{t('select_audience_description3')}
                    </p>

                    <h4 className="font-bold">{t('labels')}</h4>

                    <ul className="flex flex-wrap min-h-[150px]">
                        <li>
                            <span
                                className={classNames({
                                    badge: true,
                                    'cursor-pointer': true,
                                    'px-5': true,
                                    'badge-primary':
                                        labelsSelected.includes('all_labels'),
                                })}
                                onClick={() => handleToggleLabel('all_labels')}
                            >
                                {t('all_recipients')}
                                <span className="badge badge--single ml-[2px] right-[-3px]">
                                    {customersTotal}
                                </span>
                            </span>
                        </li>
                        <li>
                            <span
                                className={classNames({
                                    badge: true,
                                    'cursor-pointer': true,
                                    'px-5': true,
                                    'badge-primary':
                                        labelsSelected.includes('no_labels'),
                                })}
                                onClick={() => handleToggleLabel('no_labels')}
                            >
                                {t('no_label')}
                                <span className="badge badge--single ml-[2px] right-[-3px]">
                                    {customersTotal}
                                </span>
                            </span>
                        </li>
                    </ul>
                    <div className="flex">
                        <Button
                            className="btn btn-primary ml-auto"
                            disabled={!stepAvailable}
                            onClick={() => setStep(2)}
                        >
                            {t('next_step')}
                            <svg
                                className="w-5 ml-2"
                                fill="currentColor"
                                viewBox="0 0 20 20"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    fillRule="evenodd"
                                    d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z"
                                    clipRule="evenodd"
                                ></path>
                            </svg>
                        </Button>
                    </div>
                </div>
            )}
        </div>
    );
}
