import React, { createContext, useContext } from 'react';

/**
 * BulkMessage context.
 * Manages all the variable that are required
 */
export const BulkMessageContext = createContext({});

/**
 * Hook to use the BulkMessage context
 *
 * @returns The BulkMessage context
 */
export function useBulkMessage() {
    return useContext(BulkMessageContext);
}

/**
 * BulkMessage provider
 *
 * @param {any[]} props - The custom props of the component
 * @param {JSX.Element} props.children - The children
 * @returns {JSX.Element} A react component
 */
export function BulkMessageProvider({ children, values }) {
    return (
        <BulkMessageContext.Provider value={values}>
            {children}
        </BulkMessageContext.Provider>
    );
}
