import React, { useState } from 'react';
import { t } from 'i18next';
import { Button, Group, Modal, Textarea } from '@mantine/core';
import { useForm } from '@mantine/form';
import { subscriptionService } from "@resources/services";

export function CancelSubscriptionModal({ id, hideModal }) {
    const [loading, setLoading] = useState(false);

    const form = useForm({
        mode: 'uncontrolled',
        initialValues: {
            cancellation_reason: ''
        },

        validate: {
            cancellation_reason: (value) => value !== '' ? null : 'Required',
        },
    });

    const handleSubmit = (values) => {
        setLoading(true);

        subscriptionService.cancelSubscription(values).then(() => {
            hideModal(id);
        }).finally(() => setLoading(false));
    };

    return (
        <Modal.Root opened={true} onClose={hideModal} size="400px">
            <Modal.Overlay color="gray" opacity="0.55" blur="3"/>

            <Modal.Content className="bg-transparent overflow-hidden shadow-none">
                <div className="p-8 bg-white shadow mt-24">
                    <div className="text-center text-2xl">
                        {t('cancel_subscription_modal.title')}
                    </div>

                    <div className="mt-4 pb-6 text-gray-500 text-justify">
                        {t('cancel_subscription_modal.cancel_subscription_description')}
                    </div>

                    <form onSubmit={form.onSubmit(handleSubmit)}>
                        <Textarea
                            label="Tell us, Why leaving us?"
                            withAsterisk
                            placeholder="Message"
                            minRows={4}
                            {...form.getInputProps('cancellation_reason')}
                        />
                        <Group justify="end" mt="md">
                            <Button loading={loading} type="submit" className="btn btn-primary ml-auto">
                                {t('cancel_subscription_modal.cancel_subscription_button')}
                            </Button>
                        </Group>
                    </form>

                </div>
            </Modal.Content>
        </Modal.Root>
    );
}
