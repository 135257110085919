//indonesio id
export default {
    translations: {
        search: 'Cari',
        social_inbox_marketing_is_born: 'Pemasaran Kotak Masuk Sosial Lahir',
        login_with_facebook: 'Masuk dengan Facebook',
        logout: 'Keluar',
        login_description: `
            <strong>Kirim</strong> pesan
            <strong>inbox</strong> yang ditujukan kepada
            <strong>ribuan orang</strong> yang sudah
            memulai percakapan dengan halaman Anda.
            <br />
            <br />
            Pilih label yang Anda inginkan dan kirimkan promosi, berita, atau
            apa pun yang ingin Anda bagikan.
        `,
        search_page: 'Cari halaman...',
        people_like_this: '{{ people }} menyukai ini',
        view_page: 'Lihat halaman',
        add_a_new_page: 'Tambahkan halaman baru',
        support: 'Butuh bantuan?',
        earn_money_with_us: 'Dapatkan uang bersama kami',
        learn_more: 'Pelajari lebih lanjut',
        available_messages: 'Anda memiliki {{ people }} pesan yang tersedia.',
        need_more_messages: 'Butuh lebih banyak pesan?',
        messages: 'Pesan',
        search_and_filter_conversations: 'Cari dan filter percakapan',
        loading: 'Memuat',
        loading_chat_messages: 'Memuat pesan obrolan',
        loading_all_recipients: 'Memuat semua penerima',
        send_message: 'Kirim pesan',
        send_bulk_message: 'Pesan Massal Baru',
        no_such_more_messages: ' Tidak ada pesan lebih lanjut',
        write_your_message: 'Tulis pesan Anda',
        upload_image: 'Unggah gambar',
        incomplete_form: 'Formulir tidak lengkap',
        message_required: 'Silakan masukkan pesan',
        you_have_no_messages: 'Anda tidak memiliki pesan yang tersedia',
        please_purchase_a_subscription:
            'Silakan beli langganan untuk mengirim pesan',
        buy_a_subscription: 'Beli langganan',
        buy_a_addon: 'Beli Addon',
        sending_message: 'Mengirim pesan',
        sending_messages_description:
            'Mengirim pesan kepada {{people}} penerima.',
        step: 'Langkah {{step}}',
        select_audience: 'Pilih audiens Anda',
        select_fb_tag: 'Pilih jenis konten pesan Anda',
        select_audience_description1:
            'Pilih label untuk memilih kepada siapa pesan akan dikirimkan',
        select_audience_description2:
            'Gunakan "semua penerima" untuk memilih semua di kotak masuk halaman Anda',
        select_audience_description3:
            'Anda juga dapat menggunakan label untuk mengeluarkan orang',
        labels: 'Label',
        recipients: 'Penerima',
        all_recipients: 'Semua penerima',
        no_label: 'Tanpa label',
        next_step: 'Langkah berikutnya',
        select_fb_tag_description:
            'Untuk orang-orang di kotak masuk Anda yang berinteraksi lebih dari 24 jam yang lalu',
        tag_confirmed_event_update: 'Konfirmasi Pembaruan Acara',
        tag_confirmed_event_update_description: `
            Untuk orang yang mengonfirmasi kehadiran pada suatu acara.
        `,
        tag_post_purchase_update: 'Pembaruan Setelah Pembelian',
        tag_post_purchase_update_description: `
            Untuk orang yang telah melakukan pembelian di aplikasi Anda.
        `,
        tag_account_update: 'Pembaruan Akun',
        tag_account_update_description: `
            Untuk orang yang memiliki akun terdaftar di aplikasi Anda.
        `,
        select_fb_tag_footer_description: `
            Pilih jenis konten ini untuk mengirimkan
            Pesan yang tidak termasuk dalam kategori ini dapat
            mengakibatkan suspensi halaman Facebook™ Anda.
            <strong>
                KIRIM PROMOSI MENGGUNAKAN JENIS KONTEN DI ATAS
                RESIKO ANDA SENDIRI.
            </strong>
        `,
        more_info: 'Informasi lebih lanjut',
        write_your_message_and_send: 'Tulis pesan Anda dan kirim',
        this_message_will_be_sent_with_a_watermark:
            "Pesan ini akan dikirim dengan watermark reply'em, jika Anda ingin mengirim pesan tanpa watermark kami, Anda dapat membeli langganan.",
        this_messsage_will_be_sent_only_to:
            'Pesan ini hanya akan dikirimkan kepada {{people}} penerima.',
        messages_rejected: '{{ messagesRejected }} Pesan ditolak',
        messages_sent_successfully: `
            <strong>{{ messagesSent }}</strong> pesan terkirim,
            <strong>{{ errorMessages }}</strong> ditolak.
        `,
        messages_in_progress: `Mengirim <strong>{{ messagesSent }}</strong>/<strong>{{ totalMessages }}</strong> pesan.`,
        affiliates: {
            title: 'Afiliasi',
            subtitle: 'Dapatkan uang bersama kami',
            how_it_works: 'Bagaimana cara kerjanya?',
            how_it_works_description: `
                Anda dapat mendapatkan uang dengan mengundang teman, keluarga, atau siapa pun,
                <strong class="text-primary">
                    Anda akan menerima 20% dari semua pembelian yang dilakukan oleh referensi Anda selama 2 bulan
                </strong>
                , referensi Anda akan mendapatkan diskon 15% selama 3 bulan pada semua rencana apa pun.`,
            sign_up: 'Daftar',
            sign_up_description: `
                Untuk mulai menghasilkan uang, Anda hanya perlu mendaftar dalam program afiliasi kami.
            `,
            invites: 'Undang',
            invites_description: `
                Undang semua teman, keluarga, atau siapa pun untuk menggunakan
                replyem dengan tautan yang akan kami hasilkan dalam program referensi kami.
            `,
            earn_money: 'Dapatkan uang',
            earn_money_description:
                'Mulailah menghasilkan uang bersama kami dengan mudah',
            start_earning_money_now: 'Mulai menghasilkan uang sekarang',
            terms: 'Persyaratan',
            terms_description: `
                Anda dapat meminta pembayaran pertama Anda setelah mencapai saldo minimum $10. Pembayaran akan diproses melalui
                PayPal. Selain itu, Anda memiliki kesempatan untuk mendapatkan komisi 20%
                dari semua pembelian yang dilakukan pengguna Anda dalam 60 hari pertama sejak mereka mendaftar. Anda dapat
                melacak semua penghasilan Anda di tautan berikut
            `,
        },
        popular: 'Populer',
        massive_messages: 'Pesan Massal',
        massive_replies: 'Balasan Massal',
        pages: 'Halaman',
        labeling: 'Pemberian Label',
        choose_plan: 'Pilih Rencana',
        choose_your_plan: 'Pilih Rencana Anda',
        rederal_discount: 'Diskon Referal',
        massive: 'Massal',
        month: 'Bulan',
        the_number_message: 'Jumlah pesan adalah bulanan',
        plans: 'Rencana',
        union_y: 'dan',
        plans_page: {
            title: 'Rencana Anda telah berakhir.',
            description: `Jangan khawatir, Anda dapat memilih rencana dan
                terus menikmati manfaat yang ditawarkan oleh reply'em.`,
            mistake: 'Jika Anda memiliki rencana aktif',
            link_mistake: 'hubungi kami',
            referral_discount:
                'Diskon referral ditampilkan di halaman berikutnya.',
            starter_header: 'Pemula',
            pequeno_negocio: 'Usaha Kecil',
            marketer_bussines: 'Pemasar',
            ilimitado: 'Tidak Terbatas',
            starter_price_annually:
                'atau {{ priceStarterAnnualy }} saat membayar setahun',
            pequeno_negocio_price_annually:
                'atau {{ priceNegocioAnnualy }} saat membayar setahun',
            marketer_price_annually:
                'atau {{priceMarketerAnnualy}} saat membayar setahun',
            unlimited_price_annually:
                'atau {{priceUnlimitedAnnualy}} saat membayar setahun',
            months: '/bulan',
            unlimited: 'Tidak Terbatas',
            suscribe: 'Berlangganan',
        },
    },
};
