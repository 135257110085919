export default {
    translations: {
        created: 'Created',
        search: 'Search',
        social_inbox_marketing_is_born: 'Social Inbox Marketing is born',
        login_with_facebook: 'Continue with Facebook',
        logout: 'Logout',
        login_description: `
            <strong>Send</strong> targeted inbox
            <strong>messages</strong> by
            <strong>thousands to the people</strong> that has
            already started a conversation with your page.
            <br />
            <br />
            Select the tags you want and send a promotion, news or
            whatever you want to share.
        `,
        search_page: 'Search page...',
        people_like_this: '{{ people }} People like this',
        view_page: 'View page',
        add_a_new_page: 'Add a new page',
        support: 'Need support?',
        earn_money_with_us: 'Earn money with us',
        learn_more: 'Learn more',
        available_messages: 'You have {{ people }} messages left.',
        need_more_messages: 'Need more messages?',
        messages: 'Messages',
        search_and_filter_conversations: 'Search and filter conversations',
        loading: 'Loading',
        loading_chat_messages: 'Loading chat messages',
        loading_all_recipents: 'Loading all recipients',
        send_message: 'Send message',
        send_bulk_message: 'send new bulk message',
        no_such_more_messages: ' No such more messages',
        write_your_message: 'Write your message',
        upload_image: 'Upload image',
        incomplete_form: 'Incomplete form',
        message_required: 'Please enter a message',
        you_have_no_messages: "You don't have any messages available",
        please_purchase_a_subscription:
            'Please purchase a subscription to send messages',
        buy_a_subscription: 'Purchase a subscription',
        buy_a_addon: 'Purchase an addon',
        sending_message: 'Sending message',
        sending_messages_description:
            'Sending message to {{ people }} recipients, well done!',
        step: 'Step {{step}}',
        select_audience: 'Select your audience',
        select_fb_tag: 'Select the content type that fits best your message',
        select_audience_description1:
            'Select tags to choose who to send a message to',
        select_audience_description2:
            'Use “all recipients” to select everyone in your page inbox',
        select_audience_description3: 'You can also use tags to exclude people',
        labels: 'Labels',
        recipents: 'Recipients',
        all_recipients: 'All recipients',
        no_label: 'No label',
        next_step: 'Next step',
        select_fb_tag_description:
            'For people in your inbox that interacted more than 24 hours ago',
        tag_confirmed_event_update: 'Confirmed event update',
        tag_confirmed_event_update_description: `
            Send the user reminders or
            updates for an event they have
            registered for (e.g.,
            RSVP&apos;ed, purchased
            tickets). This tag may be used
            for upcoming events and events
            in progress.`,
        tag_post_purchase_update: 'Post purchase update',
        tag_post_purchase_update_description: `
            Notify the user of an update on
            a recent purchase. Messages like
            confirmation of transactions,
            invoices, receipts, shipments
            status, and order changes are
            allowed.`,
        tag_account_update: 'Account update',
        tag_account_update_description: `
            Notify the user of a
            non-recurring change to their
            application or account.`,
        select_fb_tag_footer_description: `
             Selecting these kind of content types to send
                            messages that do not belong to these categories may
                            result in your page being suspended by Facebook™.
                            <strong>
                                SEND PROMOTIONS USING THE ABOVE CONTENT TYPES AT
                                YOUR OWN RISK.
                            </strong>`,
        more_info: 'More info',
        write_your_message_and_send: 'Write your message and send',
        this_message_will_be_sent_with_a_watermark: `This message will be sent with a reply’em watermark, if
        you wish to send your message without our watermark you
        can purchase a subscription.`,
        this_messsage_will_be_sent_only_to:
            'This message will be sent only to {{ people }} users',
        messages_rejected: '{{ messagesRejected }} rejected messages.',
        messages_sent_successfully: `
            Successfully sent
            <strong>{{ messagesSent }}</strong> messages,
            <strong>{{ errorMessages }}</strong> rejected.
        `,
        messages_in_progress: `Sending <strong>{{ messagesSent }}</strong>/<strong>{{ totalMessages }}</strong> messages.`,
        affiliates: {
            title: 'Affiliates',
            subtitle: 'Earn a lot of money by referring our services',
            how_it_works: 'How it works?',
            how_it_works_description: `
                You can earn money by referring our services to your
                friends, family, or anyone.
                <strong class="text-primary">
                    You will receive 20% of all purchases made by your
                    referral for 2 months
                </strong>
                , they will receive a 15% discount for 3 months of the
                contracted plan.`,
            sign_up: 'Sign up',
            sign_up_description:
                'Sign up for our referral plan at the following link',
            invites: 'Invites',
            invites_description: `
                Invite all your friends, family or anyone else to use
                replyem with the link we will generate in the referral
                program.`,
            earn_money: 'Earn money',
            earn_money_description: 'Start earning money with us so easy',
            start_earning_money_now: 'Start earning money now',
            terms: 'Terms',
            terms_description: `
                You can request payouts once you&apos;ve reached a
                minimum balance of $10. Payments will be processed through
                PayPal. Additionally, you have the opportunity to earn a 20%
                commission on all purchases made by your referred users for a
                duration of 60 days from their registration date. You can
                monitor all your earnings in the following link
            `,
        },
        popular: 'Popular',
        massive_messages: 'massive messages',
        massive_replies: 'massive replies',
        pages: 'Pages',
        labeling: 'Labeling',
        choose_plan: 'Choose plan',
        choose_your_plan: 'Choose your plan',
        referal_discount:
            'The referral discount will be reflected on thefollowing page',
        massive: 'massive',
        month: 'month',
        the_number_message: 'The number of messages are monthly',
        plans: 'Plans',
        union_y: 'and',

        plans_page: {
            title: 'Your plan has expired.',
            description: `Don't worry, you can choose a plan and
                continue enjoying the benefits that reply'em offers.`,
            mistake: 'If you have an active plan',
            link_mistake: 'contact us',
            referral_discount:
                'The referral discount is displayed on the next page.',
            starter_header: 'Starter',
            pequeno_negocio: 'Small Business',
            marketer_bussines: 'Marketer',
            ilimitado: 'Unlimited',
            starter_price_annually:
                'or {{ priceStarterAnnualy }} when paying annually',
            pequeno_negocio_price_annually:
                'or {{ priceNegocioAnnualy }} when paying annually',
            marketer_price_annually:
                'or {{priceMarketerAnnually}} when paying annually',
            unlimited_price_annually:
                'or {{priceUnlimitedAnnually}} when paying annually',
            months: '/month',
            unlimited: 'Unlimited',
            suscribe: 'Subscribe',
        },

        user_modal: {
            subscription_end: 'Subscription end',
            list_subscriptions: 'Information about the subscription',
            cancel_subscription: 'Cancel Subscription',
            subscription_canceled: 'Subscription canceled',
            amount: 'Amount',
            interval: 'Interval',
        },

        cancel_subscription_modal: {
            title: 'Cancel subscription',
            cancel_subscription_description: `
                Are you sure you want to cancel your subscription?
                You will lose all the benefits of the plan.
            `,
            cancel_subscription_button: 'Cancel Subscription',
        }
    },
};
