import React, { useEffect } from 'react';
import classNames from 'classnames';
import { useBulkMessage } from '../../BulkMessageContext';
import { Button } from '@mantine/core';
import { t } from 'i18next';

export function Step2() {
    const {
        step,
        customersSelected,
        setStep,
        facebookTagSelected,
        setFacebookTagSelected,
    } = useBulkMessage();

    useEffect(() => {
        setFacebookTagSelected('CONFIRMED_EVENT_UPDATE');
    }, []);

    const step2IsAvailable = customersSelected.length > 0;
    const step3IsAvailable = step2IsAvailable && facebookTagSelected.length > 0;

    return (
        <div>
            <div
                className="font-bold cursor-pointer"
                onClick={() => step2IsAvailable && setStep(2)}
            >
                {t('step', { step: 2 })}: {t('select_fb_tag')}
                <div
                    className={classNames({
                        arrow: true,
                        'arrow-up': step !== 2,
                        'arrow-down': step === 2,
                    })}
                ></div>
            </div>
            {step === 2 && (
                <div className="p-3">
                    <div className="mb-3 ml-3 text-[14px]">
                        <div className="mb-2">
                            <div className="message-type">
                                <div className="radio-custom">
                                    <input
                                        type="radio"
                                        id="tag_CONFIRMED_EVENT_UPDATE"
                                        name="tag"
                                        value="CONFIRMED_EVENT_UPDATE"
                                        onChange={() =>
                                            setFacebookTagSelected(
                                                'CONFIRMED_EVENT_UPDATE'
                                            )
                                        }
                                        defaultChecked={
                                            facebookTagSelected ==
                                            'CONFIRMED_EVENT_UPDATE'
                                        }
                                    />
                                    <label htmlFor="tag_CONFIRMED_EVENT_UPDATE">
                                        <span className="ttip">
                                            {t('tag_confirmed_event_update')}
                                            <span className="ttip--content">
                                                {t(
                                                    'tag_confirmed_event_update_description'
                                                )}
                                            </span>
                                        </span>
                                    </label>
                                </div>
                                <div className="radio-custom">
                                    <input
                                        type="radio"
                                        id="tag_POST_PURCHASE_UPDATE"
                                        name="tag"
                                        value="POST_PURCHASE_UPDATE"
                                        onChange={() =>
                                            setFacebookTagSelected(
                                                'POST_PURCHASE_UPDATE'
                                            )
                                        }
                                        defaultChecked={
                                            facebookTagSelected ==
                                            'POST_PURCHASE_UPDATE'
                                        }
                                    />
                                    <label htmlFor="tag_POST_PURCHASE_UPDATE">
                                        <span className="ttip">
                                            {t('tag_post_purchase_update')}
                                            <span className="ttip--content">
                                                {t(
                                                    'tag_post_purchase_update_description'
                                                )}
                                            </span>
                                        </span>
                                    </label>
                                </div>
                                <div className="radio-custom">
                                    <input
                                        type="radio"
                                        id="tag_ACCOUNT_UPDATE"
                                        name="tag"
                                        value="ACCOUNT_UPDATE"
                                        onChange={() =>
                                            setFacebookTagSelected(
                                                'ACCOUNT_UPDATE'
                                            )
                                        }
                                        defaultChecked={
                                            facebookTagSelected ==
                                            'ACCOUNT_UPDATE'
                                        }
                                    />
                                    <label htmlFor="tag_ACCOUNT_UPDATE">
                                        <span className="ttip">
                                            {t('tag_account_update')}
                                            <span className="ttip--content">
                                                {t(
                                                    'tag_account_update_description'
                                                )}
                                            </span>
                                        </span>
                                    </label>
                                </div>
                            </div>
                        </div>

                        <div className="text-[12px] text-gray-400">
                            <span
                                dangerouslySetInnerHTML={{
                                    __html: t(
                                        'select_fb_tag_footer_description'
                                    ),
                                }}
                            />
                            <a
                                href="https://developers.facebook.com/docs/messenger-platform/send-messages/message-tags#new_supported_tags"
                                target="_blank"
                                rel="noreferrer"
                                className="text-gray-500 ml-2 font-bold"
                            >
                                <i className="fa fa-question-circle"></i>{' '}
                                {t('more_info').toUpperCase()}
                            </a>
                        </div>
                    </div>

                    <div className="flex">
                        <Button
                            className="btn btn-primary ml-auto"
                            disabled={!step3IsAvailable}
                            onClick={() => setStep(3)}
                        >
                            {t('next_step')}
                            <svg
                                className="w-5 ml-2"
                                fill="currentColor"
                                viewBox="0 0 20 20"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    fillRule="evenodd"
                                    d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z"
                                    clipRule="evenodd"
                                ></path>
                            </svg>
                        </Button>
                    </div>
                </div>
            )}
        </div>
    );
}
