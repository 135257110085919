import { facebookService } from 'resources/services';
import { CUSTOMER_SELECTED } from './types';

export const recipientMessageActions = {
    getNextMessages:
        (pageAccessToken, recipientId) => async (dispatch, getState) => {
            const { customerSelected } = getState();

            dispatch({ type: CUSTOMER_SELECTED.LOADING_MESSAGES });

            return facebookService
                .getRecipientMessages(pageAccessToken, recipientId, {
                    after: customerSelected.afterToken,
                })
                .then((response) => {
                    const { data, paging } = response;

                    return dispatch({
                        type: CUSTOMER_SELECTED.SET,
                        payload: {
                            messages: [
                                ...new Set([
                                    ...customerSelected.messages,
                                    ...data,
                                ]),
                            ],
                            afterToken: paging?.cursors?.after,
                            beforeToken: paging?.cursors?.before,
                            isLastMessage: paging?.next === undefined,
                            preLatestMessageId:
                                customerSelected.messages[
                                    customerSelected.messages.length - 1
                                ]?.id,
                        },
                    });
                })
                .finally(() =>
                    dispatch({ type: CUSTOMER_SELECTED.LOADED_MESSAGES })
                );
        },

    resetMessages: () => async (dispatch) => {
        dispatch({
            type: CUSTOMER_SELECTED.RESET_MESSAGES,
        });
    },

    setLoadingMessages: () => async (dispatch) => {
        dispatch({
            type: CUSTOMER_SELECTED.LOADING_MESSAGES,
        });
    },
};
