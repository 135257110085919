import axios from 'axios';

export const userService = {
    login(accessToken) {
        return axios
            .post('login', {
                access_token: accessToken,
                referral: window?.Rewardful?.referral || null,
                coupon: window?.Rewardful?.coupon?.id || null,
            })
            .then((res) => res.data);
    },

    getAvailableMessages() {
        return axios.get('user/available-messages').then((res) => res.data);
    },

    getSubscriptions() {
        return axios.get('user/subscriptions').then((res) => res.data);
    },

    showMySubscription() {
        return axios.get(`user/subscriptions/my-subscription`)
            .then((res) => res.data);
    },

    getUser() {
        return axios.get('user').then((res) => res.data);
    },
};
