import React from 'react';
import classNames from 'classnames';
import { useSingleBulkMessage } from 'resources/hooks';
import { t } from 'i18next';

export function BulkMessage({ bulkMessage, onClose = () => {} }) {
    const { sent, messagesSent, errorMessages, totalMessages } =
        useSingleBulkMessage(bulkMessage);

    const barProgress = Math.min((messagesSent / totalMessages) * 100, 100);

    return (
        <div className="relative mb-3 bg-white rounded-lg p-2">
            <div className="absolute top-0 right-0" onClick={onClose}>
                <div className="bg-gray-200 px-2 cursor-pointer">x</div>
            </div>
            <div className="flex justify-between items-center">
                <div className="mb-2">
                    <div>
                        <p className="text-xs font-light text-gray-600 mr-[15px]">
                            {sent ? (
                                <span
                                    dangerouslySetInnerHTML={{
                                        __html: t(
                                            'messages_sent_successfully',
                                            {
                                                messagesSent,
                                                errorMessages,
                                            }
                                        ),
                                    }}
                                />
                            ) : (
                                <span
                                    dangerouslySetInnerHTML={{
                                        __html: t('messages_in_progress', {
                                            messagesSent,
                                            totalMessages,
                                        }),
                                    }}
                                />
                            )}
                        </p>
                    </div>
                </div>
            </div>
            <div
                title={`${barProgress}%`}
                className={classNames({
                    'progress-bar': true,
                    'progress-bar-success': barProgress === 100,
                })}
            >
                <div
                    className="progress"
                    style={{ width: `${barProgress}%` }}
                ></div>
            </div>
            {!sent && (
                <div className="font-light text-gray-400 mt-1 text-xs">
                    {t('messages_rejected', {
                        messagesRejected: errorMessages,
                    })}
                </div>
            )}
        </div>
    );
}
