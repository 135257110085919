// Khmer (camboyano)
export default {
    translations: {
        search: 'ស្វែងរក',
        social_inbox_marketing_is_born: 'ការថែទាំការថែរកប្រវត្តិបង្កើតឡើងនេះ',
        login_with_facebook: 'ចូលប្រើជាមួយនឹង Facebook',
        logout: 'ចាកចេញ',
        login_description: `
            <strong>ផ្ញើ</strong> សារទៅក្នុងប្រអប់សារ
            <strong>កាមេរ៉េ</strong> ទៅ
            <strong>មនុស្សដែលបានចាប់ផ្ដើមការសរសេរ</strong> ជាមួយអ្នកនៅក្នុងទំព័ររបស់អ្នករួចហើយ។
            <br />
            <br />
            ជ្រើសរើសស្លាកដែលអ្នកចង់ផ្ញើ និងផ្ញើសារប្រាក់ចុងក្រោយ ព័ត៌មានផ្សព្វផ្សាយ ឬអ្នកអាចចែកចាយបាន។
        `,
        search_page: 'ស្វែងរកទំព័រ...',
        people_like_this: '{{ people }} មនុស្សស្រឡាញ់រឺចូលចិត្តនេះ',
        view_page: 'មើលទំព័រ',
        add_a_new_page: 'បន្ថែមទំព័រថ្មី',
        support: 'ត្រូវការគាំទ្រ?',
        earn_money_with_us: 'រកលុយជាមួយយើង',
        learn_more: 'សម្រាប់យំនួយបន្ថែម',
        available_messages: 'អ្នកមាន {{ people }} សារដែលត្រូវការ។',
        need_more_messages: 'ត្រូវការសារច្រើនជាមួយ?',
        messages: 'សារ',
        search_and_filter_conversations: 'ស្វែងរកនិយាយនិងត្រួតពិនិត្យសម្លេង',
        loading: 'កំពុងផ្ទុក',
        loading_chat_messages: 'កំពុងផ្ទុកសារជជែក',
        loading_all_recipents: 'កំពុងផ្ទុកអ្នកទទួលបានទាំងអស់',
        send_message: 'ផ្ញើសារ',
        send_bulk_message: 'ផ្ញើសារច្រើនការ',
        no_such_more_messages: ' មិនមានសារបន្ថែមនេះទេ',
        write_your_message: 'សរសេរសាររបស់អ្នក',
        upload_image: 'ផ្ទុករូបភាព',
        incomplete_form: 'ទម្រង់រួចមិនទាន់បញ្ចប់',
        message_required: 'សូមបញ្ចូលសារ',
        you_have_no_messages: 'អ្នកមិនមានសារទេ',
        please_purchase_a_subscription: 'សូមទិញកិច្ចការរបស់អ្នកដើម្បីផ្ញើសារ',
        buy_a_subscription: 'ទិញកិច្ចការ',
        buy_a_addon: 'ទិញសម្ភារៈ',
        sending_message: 'កំពុងផ្ញើសារ',
        sending_messages_description:
            'កំពុងផ្ញើសារទៅកាន់អ្នកទទួលបាន {{ people }} និងស្ថានភាពល្អ!',
        step: 'ជំហានទី {{step}}',
        select_audience: 'ជ្រើសរើសអត្តសញ្ញាសម្រាប់អ្នកស្រាវជ្រាវ',
        select_fb_tag:
            'ជ្រើសរើសប្រភេទមាតិការដែលត្រូវគិតមកសាររបស់អ្នកប្រើប្រាស់ខ្ពស់បំផុត',
        select_audience_description1: 'ជ្រើសស្លាកដើម្បីជ្រើសរើសនិយាយមកពីនរណាទៅ',
        select_audience_description2:
            'ប្រើ "អ្នកទទួលបានទាំងអស់" ដើម្បីជ្រើសរើសអ្នកក្នុងប្រអប់សាររបស់អ្នកទាំងអស់',
        select_audience_description3:
            'អ្នកអាចប្រើស្លាកដើម្បីដកចេញមនុស្សពីការផ្តល់សាររបស់អ្នកបានផងដែរ',
        labels: 'ស្លាក',
        recipents: 'អ្នកទទួលបាន',
        all_recipients: 'អ្នកទទួលបានទាំងអស់',
        no_label: 'គ្មានស្លាក',
        next_step: 'ជំហានបន្ទាប់',
        select_fb_tag_description:
            'សម្រាប់មនុស្សនៅក្នុងប្រអប់សាររបស់អ្នកដែលបានធ្វើសមាសត្រលើ 24 ម៉ោងមុន',
        tag_confirmed_event_update: 'ការធ្វើសមាសត្រកម្មនេះត្រូវបានបញ្ជាក់',
        tag_confirmed_event_update_description: `
            ផ្ញើការការពេលជូនដំណឹងឬការធ្វើសមាសត្រកម្មទៅអ្នកប្រើប្រាស់ដែលបានចុះឈ្មោះសមាសត្រ (ឧ.ប.ទំនិញបានទិញសាកល្បង់១ ឬទំនិញបានចុះបញ្ជាក់២០១១០១ កម្មវិធីផ្សេងៗត្រូវបានអនុវត្តបាន។
            កម្មវិធីនេះអាចត្រូវប្រើសមាសត្រសកម្មនៅលើព្រឹត្តិការណ៍នៅពេលដែលមកសារនេះមានផ្នែកទុកដល់។`,
        tag_post_purchase_update: 'ការធ្វើសមាសត្រនៅក្រៅការទិញទំនិញ',
        tag_post_purchase_update_description: `
            អ៊ីសារជូនដំណឹងអ្នកប្រើប្រាស់អំពីការធ្វើសមាសត្រនេះបច្ចុប្បន្នការទិញថ្មី។ សារដូចជា ការបញ្ជាក់អំពីកិច្ចការធ្វើសមាសត្រនៅពេលដែលមានការទិញផ្នែកកម្មវិធីដែលបើបញ្ជាក់ថាជាទិញថ្មី។
        `,
        tag_account_update: 'ការធ្វើសមាសត្រអតិបរិមាន',
        tag_account_update_description: `
            អ៊ីសារជូនដំណឹងអ្នកប្រើប្រាស់អំពីការផ្លាស់ប្តូរដល់កម្មវិធីរបស់ពួកគេ ឬគម្រោងរបស់ពួកគេ។
        `,
        select_fb_tag_footer_description: `
            ជ្រើសរើសប្រភេទមាតិការដែលមានផ្នែកចេញពីផ្នែកបច្ចុប្បន្នការដែលមិនសមរម្យនេះអាចធ្វើឲ្យបំផុតការរៀបចំប្រទេស Facebook™ រាល់ពេលអ្នកសំរួលចុះឈ្មោះអ៊ីប្រកាន់។
            <strong>
                ផ្ញើការផ្សព្វផ្សាយដែលមានផ្នែកចេញពីផ្នែកបច្ចុប្បន្នការនេះគ្រាន់តែធ្វើឲ្យផ្ទុកប្រើនៅពេលអ្នកសំរួលចុះឈ្មោះរបស់អ្នក។
            </strong>`,
        more_info: 'ព័ត៌មានបន្ថែម',
        write_your_message_and_send: 'សរសេរសាររបស់អ្នកហើយផ្ញើ',
        this_message_will_be_sent_with_a_watermark: `សារនេះនឹងត្រូវបានផ្ញើជាមួយនិងការសមាសត្រនៅក្នុងការឆូតស្ថាន'លេមប្រឡារចុះឈ្មោះ ប្រសិនបើ
        អ្នកចង់ផ្ញើសាររបស់អ្នកជាមួយយើងដោយមិនមានការផ្ទុកប្រឡារចុះឈ្មោះរបស់យើង
        អ្នកអាចទិញកិច្ចការបាន។`,
        this_messsage_will_be_sent_only_to:
            'សារនេះនឹងត្រូវបានផ្ញើតែមួយចំនួនទៅកាន់ {{ people }} អ្នកប្រើប្រាស់',
        messages_rejected: '{{ messagesRejected }} សារដែលត្រូវបានបដិសេធ។',
        messages_sent_successfully: `
            ផ្ញើសារបានដោយជោគជ័យ
            <strong>{{ messagesSent }}</strong> សារ,
            <strong>{{ errorMessages }}</strong> បានបដិសេធ។
        `,
        messages_in_progress: `កំពុងផ្ញើសារ <strong>{{ messagesSent }}</strong>/<strong>{{ totalMessages }}</strong> សារ។`,
        affiliates: {
            title: 'សមាជិកសាកល្បង',
            subtitle: 'រកលុយច្រើនដោយការយកការរបស់យើងទៅបង្ហាញ',
            how_it_works: 'របៀបវាយបញ្ចូលអ្នកស្រាវជ្រាវមានប្រាក់',
            how_it_works_description: `
                អ្នកអាចរកលុយច្រើនដោយវាយបញ្ចូលការរបស់យើងទៅដល់អ្នកសមាជិករបស់អ្នក
                មកស្រាវជ្រាវភូមិសាស្ដ្ររយៈពេល២ខែ
                <strong class="text-primary">
                    អ្នកនឹងទទួលបាន ការបញ្ចូលរបស់ពួកគេ ២០%
                </strong>
                ហើយពួកគេនឹងទទួលបានការបញ្ចូលរបស់ពួកគេតូចបំផុត ១៥%
                សម្រាប់ការរបស់ពួកគេ៣ខែក្រោយនៃកិច្ចការរបស់ពួកគេ។`,
            sign_up: 'ចុះឈ្មោះ',
            sign_up_description:
                'ចុះឈ្មោះសមាជិកសាកល្បងរបស់យើងតាមតែតំណរតំណភ្ជាប់នេះ',
            invites: 'ប្រាក់កម្មវិធី',
            invites_description: `
                ប្រាក់កម្មវិធីកាមូរមកស្រាវជ្រាវអ្នកសមាជិករបស់អ្នកដោយតំណរតំណភ្ជាប់ដែលយើងនឹងបង្កើត។
            `,
            earn_money: 'រកលុយ',
            earn_money_description: 'ចាប់ផ្តើមរកលុយជាមួយយើងងាយស្រួល',
            start_earning_money_now: 'ចាប់ផ្តើមរកលុយឥឡូវនេះ',
            terms: 'លក្ខខណ្ឌ',
            terms_description: `
                អ្នកអាចស្នើសុំការបង់ប្រាក់កាមិនដែលទូទាត់នៅពេលដែលអ្នកទាន់ខាងក្រោយដើម្បីរកលុយច្រើនមានតុលនៅ $10។ ការបង់ប្រាក់នឹងត្រូវបានដោនឡូតតាមរយៈ PayPal។ ផ្ទុកប្រឡារច្រើន អ្នកអាចមើលវានៅតំណរតំណតំណរតំណភ្ជាប់នេះ
            `,
        },
        popular: 'ទំនើប',
        massive_messages: 'សារមាស',
        massive_replies: 'ចម្លើយមាស',
        pages: 'ទំព័រ',
        labeling: 'ស្លាកស្រទាប់',
        choose_plan: 'ជ្រើសរើសបុគ្គលិក',
        choose_your_plan: 'ជ្រើសរើសបុគ្គលិករបស់អ្នក',
        rederal_discount: 'ការបញ្ចុះតំលៃសមាគម',
        massive: 'មាស',
        month: 'ខែ',
        the_number_message: 'ចំនួនសារគូប៉ុន្មានក្នុងមួយខែ',
        plans: 'កត់ត្រា',
        plans_page: {
            title: 'ផែនការរបស់អ្នកបានផុតកំណត់។',
            description: `កិច្ចការទុករបស់អ្នកនឹងមានជម្រើសដើម្បីជ្រើសរើសនិង
                បន្តរសួរនិងចាត់ទុកអត្ថប្រយោជន៏ដែលផ្តល់អោយ reply'em។`,
            mistake: 'ប្រសិនបើអ្នកមានផែនការសកម្ម',
            link_mistake: 'ទាក់ទងមកពួកយើង',
            referral_discount:
                'ការបញ្ចុះតម្លៃចែករួមត្រូវបានបង្ហាញនៅទំព័របន្ទាប់។',
            starter_header: 'ចាប់ករ',
            pequeno_negocio: 'អាជីវកម្មតូច',
            marketer_bussines: 'អ្នកលក់',
            ilimitado: 'គ្មានកំណត់',
            starter_price_annually:
                'ឬ {{ priceStarterAnnualy }} នៅពេលបង់ប្រាក់រាល់ឆ្នាំ',
            pequeno_negocio_price_annually:
                'ឬ {{ priceNegocioAnnualy }} នៅពេលបង់ប្រាក់រាល់ឆ្នាំ',
            marketer_price_annually:
                'ឬ {{priceMarketerAnnualy}} នៅពេលបង់ប្រាក់រាល់ឆ្នាំ',
            unlimited_price_annually:
                'ឬ {{priceUnlimitedAnnualy}} នៅពេលបង់ប្រាក់រាល់ឆ្នាំ',
            months: '/ខែ',
            unlimited: 'គ្មានកំណត់',
            suscribe: 'ជាវការចុះឈ្មោះ',
        },
    },
};
