export const TYPES = {
    SINGLE: 1,
    MODAL: 2,
    BULK_MESSAGE: 3,
};

export const STATUS = {
    OK: 'OK',
    NG: 'NG',
};
