import React from 'react';
import { authActions } from '@resources/actions';
import { useDispatch } from 'react-redux';
import { t } from 'i18next';

export function NewPageCard() {
    const dispatch = useDispatch();

    function handleAddNewPage() {
        dispatch(authActions.login());
    }

    return (
        <div
            className="max-w-sm bg-white border border-gray-200 shadow-md flex items-center justify-center cursor-pointer"
            onClick={handleAddNewPage}
        >
            <div className="h-[200px] bg-cover bg-center text-center flex items-center justify-center">
                <svg
                    className="w-6 h-6 mr-2 text-gray-500"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M12 4v16m8-8H4"
                    />
                </svg>
                <span className="text-gray-500">{t('add_a_new_page')}</span>
            </div>
        </div>
    );
}
